:global {
  .list-paid-stamp {
    background-image: url(../../../assets/images/paid-stamp-icon.svg);
    height: 50px;
    width: 62px;
    background-repeat: no-repeat;
    background-size: cover;
    transform: rotate(30deg);
    padding: 0.2rem 1rem;
    margin: 0 auto;
  }
}
