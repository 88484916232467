@import "../../../assets/styles/variables";
:global {
    .part-row-decline {
        position: relative;
        // opacity: 0.5;
        // filter: blur(1px);
        td {
            opacity: 0.5;
            mix-blend-mode: luminosity;
            transition: 0.3s all ease-in-out;
        }

        td:last-of-type {
            opacity: 1;
            mix-blend-mode: normal;
        }
        
        // &::after {
        //     content: "";
        //     position: absolute;
        //     left: 0;
        //     width: 100%;
        //     height: 100%;
        //     background-color: #22222233;
        //     mix-blend-mode: luminosity;
            
        //     transition: 0.3s all ease-in-out;
        //     z-index: 1;
        // }
    }

    .surface-prices {
        width: 20vw;
        @media #{$mobile} {
            width: 100vw;
        }
    }

    .b-price-info {
        // width: max-content;
        padding: 1.25rem 1rem 1rem 1rem;
        background-color: #fff;
        border-radius: 0 0 8px 8px;
        
        label {
            &:first-of-type {
                // display: flex;
                width: 100%;
            }

            &:last-of-type {
                min-width: 10rem;
                text-align: end;
            }
        }
        > div {
            align-items: center;
            &:last-of-type {
                label {
                    &:last-of-type {
                        font-size: 1.25rem;
                    }
                }
            }
        }
        @media #{$mobile} {
            width: 100%;
        }
    }
}