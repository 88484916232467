:global {
  .locale {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 100%;
    overflow: hidden;
    button {
      padding: 0;
      height: min-content;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    a {
      cursor: pointer;
      height: max-content;
    }
    object {
      width: 2.4rem;
      height: auto;
      pointer-events: none;
    }
  }
  .dropdown-locales {
    svg {
      width: 1rem;
      height: 1rem;
      margin: -0.5rem 0 -0.5rem 0;
    }
  }
}
