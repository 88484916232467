@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;900&display=swap');

$display-font-sizes: (
  1: 3.714rem,
  2: 3.143rem,
  3: 2.571rem,
  4: 2rem,
  5: 1.714rem,
  6: 1.429rem
);

:root {
  --bs-app-border: 0;
}