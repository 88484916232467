:global {
  .drop-drag-zone {
    position: relative;
    .ant-upload-drag {
      text-align: start;
      background: none;
      border: none;
      &::after {
        transform: 0.3s all ease-in;
      }
    }
    .ant-upload-drag .ant-upload {
      padding: 0;
      cursor: default;
    }
    .ant-upload-drag-hover {
      .ant-upload-drag-container * {
        pointer-events: none !important;
      }
    }
    .drag-layer {
      position: fixed;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: #22222222;
      backdrop-filter: blur(10px);
      display: flex;
      flex-direction: column;
      align-items: center;
      // z-index: -1;
      opacity: 0;
      transition: all 0.2s ease-in-out;

      padding-top: 10vw;
      pointer-events: none;

      * {
        pointer-events: none;
      }
    }
    .drag-drop-area {
      .ant-upload {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }

    &.dragging {
        .children-container * {
            pointer-events: none !important;
        }
        .drag-layer {
            opacity: 1;
            z-index: 99;
        }
    }
  }
}
