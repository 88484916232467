:global {
  .brecht {
    .tnc {
    //   @import url("https://themes.googleusercontent.com/fonts/css?kit=r3oQQBWBLofdqMg7XagtpouATS5UIGs1Tg1LXDS_dFdbV0WvE1cEyAoIq5yYZlSc");
      ol.lst-kix_list_7-0 {
        list-style-type: none;
      }
      .lst-kix_list_14-1 > li:before {
        content: "" counter(lst-ctn-kix_list_14-1, lower-latin) ". ";
      }
      .lst-kix_list_14-3 > li:before {
        content: "" counter(lst-ctn-kix_list_14-3, decimal) ". ";
      }
      .lst-kix_list_2-1 > li {
        counter-increment: lst-ctn-kix_list_2-1;
      }
      ol.lst-kix_list_9-0.start {
        counter-reset: lst-ctn-kix_list_9-0 0;
      }
      .lst-kix_list_14-0 > li:before {
        content: "(" counter(lst-ctn-kix_list_14-0, decimal) ") ";
      }
      .lst-kix_list_14-4 > li:before {
        content: "" counter(lst-ctn-kix_list_14-4, lower-latin) ". ";
      }
      .lst-kix_list_6-1 > li {
        counter-increment: lst-ctn-kix_list_6-1;
      }
      .lst-kix_list_14-5 > li:before {
        content: "" counter(lst-ctn-kix_list_14-5, lower-roman) ". ";
      }
      .lst-kix_list_14-7 > li:before {
        content: "" counter(lst-ctn-kix_list_14-7, lower-latin) ". ";
      }
      ol.lst-kix_list_13-4.start {
        counter-reset: lst-ctn-kix_list_13-4 0;
      }
      .lst-kix_list_14-6 > li:before {
        content: "" counter(lst-ctn-kix_list_14-6, decimal) ". ";
      }
      .lst-kix_list_13-0 > li {
        counter-increment: lst-ctn-kix_list_13-0;
      }
      ol.lst-kix_list_7-4.start {
        counter-reset: lst-ctn-kix_list_7-4 0;
      }
      .lst-kix_list_5-0 > li {
        counter-increment: lst-ctn-kix_list_5-0;
      }
      .lst-kix_list_9-0 > li {
        counter-increment: lst-ctn-kix_list_9-0;
      }
      ol.lst-kix_list_2-3.start {
        counter-reset: lst-ctn-kix_list_2-3 0;
      }
      ol.lst-kix_list_7-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_7-6 {
        list-style-type: none;
      }
      ol.lst-kix_list_7-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_7-8 {
        list-style-type: none;
      }
      ol.lst-kix_list_7-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_7-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_11-8.start {
        counter-reset: lst-ctn-kix_list_11-8 0;
      }
      ol.lst-kix_list_7-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_7-4 {
        list-style-type: none;
      }
      .lst-kix_list_14-2 > li:before {
        content: "" counter(lst-ctn-kix_list_14-2, lower-roman) ". ";
      }
      ol.lst-kix_list_5-3.start {
        counter-reset: lst-ctn-kix_list_5-3 0;
      }
      ol.lst-kix_list_12-0.start {
        counter-reset: lst-ctn-kix_list_12-0 0;
      }
      .lst-kix_list_4-3 > li {
        counter-increment: lst-ctn-kix_list_4-3;
      }
      ol.lst-kix_list_3-7.start {
        counter-reset: lst-ctn-kix_list_3-7 0;
      }
      .lst-kix_list_14-8 > li:before {
        content: "" counter(lst-ctn-kix_list_14-8, lower-roman) ". ";
      }
      ol.lst-kix_list_8-8.start {
        counter-reset: lst-ctn-kix_list_8-8 0;
      }
      .lst-kix_list_3-2 > li {
        counter-increment: lst-ctn-kix_list_3-2;
      }
      ol.lst-kix_list_10-4.start {
        counter-reset: lst-ctn-kix_list_10-4 0;
      }
      .lst-kix_list_7-2 > li {
        counter-increment: lst-ctn-kix_list_7-2;
      }
      .lst-kix_list_5-0 > li:before {
        content: "(" counter(lst-ctn-kix_list_5-0, decimal) ") ";
      }
      ol.lst-kix_list_6-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_6-1 {
        list-style-type: none;
      }
      .lst-kix_list_14-8 > li {
        counter-increment: lst-ctn-kix_list_14-8;
      }
      .lst-kix_list_5-4 > li {
        counter-increment: lst-ctn-kix_list_5-4;
      }
      .lst-kix_list_1-4 > li {
        counter-increment: lst-ctn-kix_list_1-4;
      }
      ol.lst-kix_list_1-6.start {
        counter-reset: lst-ctn-kix_list_1-6 0;
      }
      .lst-kix_list_5-3 > li:before {
        content: "" counter(lst-ctn-kix_list_5-3, decimal) ". ";
      }
      ol.lst-kix_list_9-5.start {
        counter-reset: lst-ctn-kix_list_9-5 0;
      }
      .lst-kix_list_5-2 > li:before {
        content: "" counter(lst-ctn-kix_list_5-2, lower-roman) ". ";
      }
      .lst-kix_list_8-3 > li {
        counter-increment: lst-ctn-kix_list_8-3;
      }
      .lst-kix_list_5-1 > li:before {
        content: "" counter(lst-ctn-kix_list_5-1, lower-latin) ". ";
      }
      .lst-kix_list_5-7 > li:before {
        content: "" counter(lst-ctn-kix_list_5-7, lower-latin) ". ";
      }
      .lst-kix_list_5-6 > li:before {
        content: "" counter(lst-ctn-kix_list_5-6, decimal) ". ";
      }
      .lst-kix_list_5-8 > li:before {
        content: "" counter(lst-ctn-kix_list_5-8, lower-roman) ". ";
      }
      .lst-kix_list_9-4 > li {
        counter-increment: lst-ctn-kix_list_9-4;
      }
      ol.lst-kix_list_6-6 {
        list-style-type: none;
      }
      ol.lst-kix_list_6-7 {
        list-style-type: none;
      }
      .lst-kix_list_5-4 > li:before {
        content: "" counter(lst-ctn-kix_list_5-4, lower-latin) ". ";
      }
      ol.lst-kix_list_6-8 {
        list-style-type: none;
      }
      .lst-kix_list_5-5 > li:before {
        content: "" counter(lst-ctn-kix_list_5-5, lower-roman) ". ";
      }
      ol.lst-kix_list_6-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_6-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_6-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_6-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_14-1.start {
        counter-reset: lst-ctn-kix_list_14-1 0;
      }
      ol.lst-kix_list_12-5.start {
        counter-reset: lst-ctn-kix_list_12-5 0;
      }
      .lst-kix_list_6-1 > li:before {
        content: "" counter(lst-ctn-kix_list_6-1, lower-latin) ". ";
      }
      .lst-kix_list_6-3 > li:before {
        content: "" counter(lst-ctn-kix_list_6-3, decimal) ". ";
      }
      .lst-kix_list_6-5 > li {
        counter-increment: lst-ctn-kix_list_6-5;
      }
      .lst-kix_list_6-8 > li {
        counter-increment: lst-ctn-kix_list_6-8;
      }
      .lst-kix_list_6-0 > li:before {
        content: "(" counter(lst-ctn-kix_list_6-0, decimal) ") ";
      }
      .lst-kix_list_6-4 > li:before {
        content: "" counter(lst-ctn-kix_list_6-4, lower-latin) ". ";
      }
      ol.lst-kix_list_14-8.start {
        counter-reset: lst-ctn-kix_list_14-8 0;
      }
      .lst-kix_list_3-6 > li {
        counter-increment: lst-ctn-kix_list_3-6;
      }
      .lst-kix_list_6-2 > li:before {
        content: "" counter(lst-ctn-kix_list_6-2, lower-roman) ". ";
      }
      .lst-kix_list_2-5 > li {
        counter-increment: lst-ctn-kix_list_2-5;
      }
      .lst-kix_list_2-8 > li {
        counter-increment: lst-ctn-kix_list_2-8;
      }
      ol.lst-kix_list_3-2.start {
        counter-reset: lst-ctn-kix_list_3-2 0;
      }
      .lst-kix_list_6-8 > li:before {
        content: "" counter(lst-ctn-kix_list_6-8, lower-roman) ". ";
      }
      .lst-kix_list_6-5 > li:before {
        content: "" counter(lst-ctn-kix_list_6-5, lower-roman) ". ";
      }
      .lst-kix_list_6-7 > li:before {
        content: "" counter(lst-ctn-kix_list_6-7, lower-latin) ". ";
      }
      .lst-kix_list_6-6 > li:before {
        content: "" counter(lst-ctn-kix_list_6-6, decimal) ". ";
      }
      ol.lst-kix_list_10-6.start {
        counter-reset: lst-ctn-kix_list_10-6 0;
      }
      .lst-kix_list_7-4 > li:before {
        content: "" counter(lst-ctn-kix_list_7-4, lower-latin) ". ";
      }
      .lst-kix_list_7-6 > li:before {
        content: "" counter(lst-ctn-kix_list_7-6, decimal) ". ";
      }
      ol.lst-kix_list_6-2.start {
        counter-reset: lst-ctn-kix_list_6-2 0;
      }
      .lst-kix_list_7-2 > li:before {
        content: "" counter(lst-ctn-kix_list_7-2, lower-roman) ". ";
      }
      .lst-kix_list_7-6 > li {
        counter-increment: lst-ctn-kix_list_7-6;
      }
      .lst-kix_list_8-6 > li {
        counter-increment: lst-ctn-kix_list_8-6;
      }
      .lst-kix_list_12-6 > li {
        counter-increment: lst-ctn-kix_list_12-6;
      }
      ol.lst-kix_list_4-6.start {
        counter-reset: lst-ctn-kix_list_4-6 0;
      }
      ol.lst-kix_list_9-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_9-8 {
        list-style-type: none;
      }
      .lst-kix_list_13-7 > li:before {
        content: "" counter(lst-ctn-kix_list_13-7, lower-latin) ". ";
      }
      ol.lst-kix_list_3-0.start {
        counter-reset: lst-ctn-kix_list_3-0 0;
      }
      ol.lst-kix_list_9-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_9-4 {
        list-style-type: none;
      }
      .lst-kix_list_5-7 > li {
        counter-increment: lst-ctn-kix_list_5-7;
      }
      ol.lst-kix_list_9-5 {
        list-style-type: none;
      }
      .lst-kix_list_7-8 > li:before {
        content: "" counter(lst-ctn-kix_list_7-8, lower-roman) ". ";
      }
      ol.lst-kix_list_9-6 {
        list-style-type: none;
      }
      .lst-kix_list_4-7 > li {
        counter-increment: lst-ctn-kix_list_4-7;
      }
      ol.lst-kix_list_9-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_9-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_9-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_2-5.start {
        counter-reset: lst-ctn-kix_list_2-5 0;
      }
      .lst-kix_list_9-8 > li {
        counter-increment: lst-ctn-kix_list_9-8;
      }
      .lst-kix_list_13-4 > li {
        counter-increment: lst-ctn-kix_list_13-4;
      }
      .lst-kix_list_4-1 > li:before {
        content: "" counter(lst-ctn-kix_list_4-1, lower-latin) ". ";
      }
      .lst-kix_list_4-3 > li:before {
        content: "" counter(lst-ctn-kix_list_4-3, decimal) ". ";
      }
      .lst-kix_list_4-5 > li:before {
        content: "" counter(lst-ctn-kix_list_4-5, lower-roman) ". ";
      }
      .lst-kix_list_1-8 > li {
        counter-increment: lst-ctn-kix_list_1-8;
      }
      .lst-kix_list_10-5 > li {
        counter-increment: lst-ctn-kix_list_10-5;
      }
      ol.lst-kix_list_1-4.start {
        counter-reset: lst-ctn-kix_list_1-4 0;
      }
      .lst-kix_list_3-5 > li {
        counter-increment: lst-ctn-kix_list_3-5;
      }
      ol.lst-kix_list_1-1.start {
        counter-reset: lst-ctn-kix_list_1-1 0;
      }
      ol.lst-kix_list_4-4.start {
        counter-reset: lst-ctn-kix_list_4-4 0;
      }
      .lst-kix_list_6-4 > li {
        counter-increment: lst-ctn-kix_list_6-4;
      }
      ol.lst-kix_list_9-2.start {
        counter-reset: lst-ctn-kix_list_9-2 0;
      }
      .lst-kix_list_9-3 > li {
        counter-increment: lst-ctn-kix_list_9-3;
      }
      .lst-kix_list_11-2 > li {
        counter-increment: lst-ctn-kix_list_11-2;
      }
      ol.lst-kix_list_2-8.start {
        counter-reset: lst-ctn-kix_list_2-8 0;
      }
      ol.lst-kix_list_8-8 {
        list-style-type: none;
      }
      .lst-kix_list_12-3 > li:before {
        content: "" counter(lst-ctn-kix_list_12-3, decimal) ". ";
      }
      ol.lst-kix_list_7-6.start {
        counter-reset: lst-ctn-kix_list_7-6 0;
      }
      ol.lst-kix_list_8-4 {
        list-style-type: none;
      }
      .lst-kix_list_12-1 > li:before {
        content: "" counter(lst-ctn-kix_list_12-1, lower-latin) ". ";
      }
      ol.lst-kix_list_8-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_8-6 {
        list-style-type: none;
      }
      ol.lst-kix_list_8-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_8-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_8-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_8-2 {
        list-style-type: none;
      }
      .lst-kix_list_13-3 > li {
        counter-increment: lst-ctn-kix_list_13-3;
      }
      ol.lst-kix_list_13-6.start {
        counter-reset: lst-ctn-kix_list_13-6 0;
      }
      ol.lst-kix_list_8-3 {
        list-style-type: none;
      }
      .lst-kix_list_10-4 > li {
        counter-increment: lst-ctn-kix_list_10-4;
      }
      .lst-kix_list_14-1 > li {
        counter-increment: lst-ctn-kix_list_14-1;
      }
      .lst-kix_list_13-3 > li:before {
        content: "" counter(lst-ctn-kix_list_13-3, decimal) ". ";
      }
      ol.lst-kix_list_5-8.start {
        counter-reset: lst-ctn-kix_list_5-8 0;
      }
      .lst-kix_list_1-3 > li {
        counter-increment: lst-ctn-kix_list_1-3;
      }
      .lst-kix_list_13-5 > li:before {
        content: "" counter(lst-ctn-kix_list_13-5, lower-roman) ". ";
      }
      .lst-kix_list_12-5 > li:before {
        content: "" counter(lst-ctn-kix_list_12-5, lower-roman) ". ";
      }
      ol.lst-kix_list_13-7.start {
        counter-reset: lst-ctn-kix_list_13-7 0;
      }
      ol.lst-kix_list_12-2.start {
        counter-reset: lst-ctn-kix_list_12-2 0;
      }
      .lst-kix_list_12-7 > li:before {
        content: "" counter(lst-ctn-kix_list_12-7, lower-latin) ". ";
      }
      ol.lst-kix_list_6-0.start {
        counter-reset: lst-ctn-kix_list_6-0 0;
      }
      .lst-kix_list_4-2 > li {
        counter-increment: lst-ctn-kix_list_4-2;
      }
      .lst-kix_list_13-1 > li:before {
        content: "" counter(lst-ctn-kix_list_13-1, lower-latin) ". ";
      }
      ol.lst-kix_list_3-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_3-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_3-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_3-4.start {
        counter-reset: lst-ctn-kix_list_3-4 0;
      }
      .lst-kix_list_5-1 > li {
        counter-increment: lst-ctn-kix_list_5-1;
      }
      ol.lst-kix_list_3-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_3-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_11-6 {
        list-style-type: none;
      }
      ol.lst-kix_list_11-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_11-8 {
        list-style-type: none;
      }
      .lst-kix_list_1-1 > li {
        counter-increment: lst-ctn-kix_list_1-1;
      }
      .lst-kix_list_7-1 > li {
        counter-increment: lst-ctn-kix_list_7-1;
      }
      ol.lst-kix_list_11-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_11-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_2-6.start {
        counter-reset: lst-ctn-kix_list_2-6 0;
      }
      .lst-kix_list_3-0 > li:before {
        content: "(" counter(lst-ctn-kix_list_3-0, decimal) ") ";
      }
      ol.lst-kix_list_11-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_11-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_13-1.start {
        counter-reset: lst-ctn-kix_list_13-1 0;
      }
      ol.lst-kix_list_11-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_11-1 {
        list-style-type: none;
      }
      .lst-kix_list_4-0 > li {
        counter-increment: lst-ctn-kix_list_4-0;
      }
      .lst-kix_list_8-0 > li {
        counter-increment: lst-ctn-kix_list_8-0;
      }
      .lst-kix_list_10-0 > li {
        counter-increment: lst-ctn-kix_list_10-0;
      }
      .lst-kix_list_3-4 > li:before {
        content: "" counter(lst-ctn-kix_list_3-4, lower-latin) ". ";
      }
      .lst-kix_list_3-3 > li:before {
        content: "" counter(lst-ctn-kix_list_3-3, decimal) ". ";
      }
      ol.lst-kix_list_3-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_3-6 {
        list-style-type: none;
      }
      .lst-kix_list_8-0 > li:before {
        content: "(" counter(lst-ctn-kix_list_8-0, decimal) ") ";
      }
      ol.lst-kix_list_3-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_3-8 {
        list-style-type: none;
      }
      .lst-kix_list_8-7 > li:before {
        content: "" counter(lst-ctn-kix_list_8-7, lower-latin) ". ";
      }
      .lst-kix_list_3-8 > li:before {
        content: "" counter(lst-ctn-kix_list_3-8, lower-roman) ". ";
      }
      ol.lst-kix_list_10-7.start {
        counter-reset: lst-ctn-kix_list_10-7 0;
      }
      .lst-kix_list_13-1 > li {
        counter-increment: lst-ctn-kix_list_13-1;
      }
      .lst-kix_list_8-3 > li:before {
        content: "" counter(lst-ctn-kix_list_8-3, decimal) ". ";
      }
      .lst-kix_list_3-7 > li:before {
        content: "" counter(lst-ctn-kix_list_3-7, lower-latin) ". ";
      }
      .lst-kix_list_8-4 > li:before {
        content: "" counter(lst-ctn-kix_list_8-4, lower-latin) ". ";
      }
      .lst-kix_list_10-2 > li {
        counter-increment: lst-ctn-kix_list_10-2;
      }
      ol.lst-kix_list_8-5.start {
        counter-reset: lst-ctn-kix_list_8-5 0;
      }
      .lst-kix_list_11-1 > li:before {
        content: "" counter(lst-ctn-kix_list_11-1, lower-latin) ". ";
      }
      .lst-kix_list_11-0 > li:before {
        content: "(" counter(lst-ctn-kix_list_11-0, decimal) ") ";
      }
      ol.lst-kix_list_9-3.start {
        counter-reset: lst-ctn-kix_list_9-3 0;
      }
      .lst-kix_list_8-8 > li:before {
        content: "" counter(lst-ctn-kix_list_8-8, lower-roman) ". ";
      }
      ol.lst-kix_list_2-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_2-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_2-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_7-2.start {
        counter-reset: lst-ctn-kix_list_7-2 0;
      }
      ol.lst-kix_list_2-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_2-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_2-1 {
        list-style-type: none;
      }
      .lst-kix_list_4-8 > li:before {
        content: "" counter(lst-ctn-kix_list_4-8, lower-roman) ". ";
      }
      ol.lst-kix_list_12-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_12-6 {
        list-style-type: none;
      }
      .lst-kix_list_4-7 > li:before {
        content: "" counter(lst-ctn-kix_list_4-7, lower-latin) ". ";
      }
      ol.lst-kix_list_12-7 {
        list-style-type: none;
      }
      .lst-kix_list_14-2 > li {
        counter-increment: lst-ctn-kix_list_14-2;
      }
      ol.lst-kix_list_12-8 {
        list-style-type: none;
      }
      ol.lst-kix_list_12-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_12-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_12-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_12-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_12-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_4-8.start {
        counter-reset: lst-ctn-kix_list_4-8 0;
      }
      .lst-kix_list_8-4 > li {
        counter-increment: lst-ctn-kix_list_8-4;
      }
      ol.lst-kix_list_3-3.start {
        counter-reset: lst-ctn-kix_list_3-3 0;
      }
      ol.lst-kix_list_10-8.start {
        counter-reset: lst-ctn-kix_list_10-8 0;
      }
      ol.lst-kix_list_2-6 {
        list-style-type: none;
      }
      ol.lst-kix_list_2-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_2-8 {
        list-style-type: none;
      }
      .lst-kix_list_11-3 > li {
        counter-increment: lst-ctn-kix_list_11-3;
      }
      ol.lst-kix_list_7-1.start {
        counter-reset: lst-ctn-kix_list_7-1 0;
      }
      ol.lst-kix_list_8-6.start {
        counter-reset: lst-ctn-kix_list_8-6 0;
      }
      ol.lst-kix_list_8-0.start {
        counter-reset: lst-ctn-kix_list_8-0 0;
      }
      .lst-kix_list_7-0 > li:before {
        content: "(" counter(lst-ctn-kix_list_7-0, decimal) ") ";
      }
      .lst-kix_list_13-8 > li {
        counter-increment: lst-ctn-kix_list_13-8;
      }
      .lst-kix_list_2-2 > li {
        counter-increment: lst-ctn-kix_list_2-2;
      }
      ol.lst-kix_list_4-7.start {
        counter-reset: lst-ctn-kix_list_4-7 0;
      }
      ol.lst-kix_list_5-0 {
        list-style-type: none;
      }
      .lst-kix_list_3-7 > li {
        counter-increment: lst-ctn-kix_list_3-7;
      }
      ol.lst-kix_list_5-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_9-7.start {
        counter-reset: lst-ctn-kix_list_9-7 0;
      }
      ol.lst-kix_list_5-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_13-8 {
        list-style-type: none;
      }
      .lst-kix_list_2-4 > li:before {
        content: "" counter(lst-ctn-kix_list_2-4, lower-latin) ". ";
      }
      .lst-kix_list_2-8 > li:before {
        content: "" counter(lst-ctn-kix_list_2-8, lower-roman) ". ";
      }
      ol.lst-kix_list_13-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_13-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_13-6 {
        list-style-type: none;
      }
      ol.lst-kix_list_13-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_13-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_13-1 {
        list-style-type: none;
      }
      .lst-kix_list_6-6 > li {
        counter-increment: lst-ctn-kix_list_6-6;
      }
      ol.lst-kix_list_13-2 {
        list-style-type: none;
      }
      .lst-kix_list_7-3 > li:before {
        content: "" counter(lst-ctn-kix_list_7-3, decimal) ". ";
      }
      ol.lst-kix_list_13-3 {
        list-style-type: none;
      }
      .lst-kix_list_10-0 > li:before {
        content: "(" counter(lst-ctn-kix_list_10-0, decimal) ") ";
      }
      .lst-kix_list_9-7 > li {
        counter-increment: lst-ctn-kix_list_9-7;
      }
      .lst-kix_list_13-6 > li {
        counter-increment: lst-ctn-kix_list_13-6;
      }
      .lst-kix_list_13-8 > li:before {
        content: "" counter(lst-ctn-kix_list_13-8, lower-roman) ". ";
      }
      ol.lst-kix_list_14-6.start {
        counter-reset: lst-ctn-kix_list_14-6 0;
      }
      ol.lst-kix_list_5-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_5-8 {
        list-style-type: none;
      }
      ol.lst-kix_list_5-3 {
        list-style-type: none;
      }
      .lst-kix_list_8-7 > li {
        counter-increment: lst-ctn-kix_list_8-7;
      }
      ol.lst-kix_list_5-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_3-8.start {
        counter-reset: lst-ctn-kix_list_3-8 0;
      }
      ol.lst-kix_list_5-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_5-6 {
        list-style-type: none;
      }
      .lst-kix_list_7-7 > li:before {
        content: "" counter(lst-ctn-kix_list_7-7, lower-latin) ". ";
      }
      ol.lst-kix_list_8-1.start {
        counter-reset: lst-ctn-kix_list_8-1 0;
      }
      .lst-kix_list_9-5 > li {
        counter-increment: lst-ctn-kix_list_9-5;
      }
      .lst-kix_list_5-8 > li {
        counter-increment: lst-ctn-kix_list_5-8;
      }
      .lst-kix_list_10-4 > li:before {
        content: "" counter(lst-ctn-kix_list_10-4, lower-latin) ". ";
      }
      .lst-kix_list_10-8 > li:before {
        content: "" counter(lst-ctn-kix_list_10-8, lower-roman) ". ";
      }
      .lst-kix_list_4-0 > li:before {
        content: "(" counter(lst-ctn-kix_list_4-0, decimal) ") ";
      }
      ol.lst-kix_list_14-3.start {
        counter-reset: lst-ctn-kix_list_14-3 0;
      }
      .lst-kix_list_4-4 > li:before {
        content: "" counter(lst-ctn-kix_list_4-4, lower-latin) ". ";
      }
      ol.lst-kix_list_2-2.start {
        counter-reset: lst-ctn-kix_list_2-2 0;
      }
      .lst-kix_list_9-3 > li:before {
        content: "" counter(lst-ctn-kix_list_9-3, decimal) ". ";
      }
      ol.lst-kix_list_7-0.start {
        counter-reset: lst-ctn-kix_list_7-0 0;
      }
      .lst-kix_list_12-8 > li {
        counter-increment: lst-ctn-kix_list_12-8;
      }
      ol.lst-kix_list_13-2.start {
        counter-reset: lst-ctn-kix_list_13-2 0;
      }
      ol.lst-kix_list_4-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_4-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_4-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_4-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_14-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_14-4.start {
        counter-reset: lst-ctn-kix_list_14-4 0;
      }
      ol.lst-kix_list_14-8 {
        list-style-type: none;
      }
      .lst-kix_list_9-7 > li:before {
        content: "" counter(lst-ctn-kix_list_9-7, lower-latin) ". ";
      }
      .lst-kix_list_2-4 > li {
        counter-increment: lst-ctn-kix_list_2-4;
      }
      ol.lst-kix_list_14-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_3-6.start {
        counter-reset: lst-ctn-kix_list_3-6 0;
      }
      ol.lst-kix_list_14-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_14-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_14-6 {
        list-style-type: none;
      }
      .lst-kix_list_11-4 > li:before {
        content: "" counter(lst-ctn-kix_list_11-4, lower-latin) ". ";
      }
      ol.lst-kix_list_14-0 {
        list-style-type: none;
      }
      .lst-kix_list_12-4 > li:before {
        content: "" counter(lst-ctn-kix_list_12-4, lower-latin) ". ";
      }
      ol.lst-kix_list_14-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_14-2 {
        list-style-type: none;
      }
      .lst-kix_list_5-3 > li {
        counter-increment: lst-ctn-kix_list_5-3;
      }
      ol.lst-kix_list_4-8 {
        list-style-type: none;
      }
      .lst-kix_list_7-4 > li {
        counter-increment: lst-ctn-kix_list_7-4;
      }
      .lst-kix_list_1-0 > li:before {
        content: "(" counter(lst-ctn-kix_list_1-0, decimal) ") ";
      }
      ol.lst-kix_list_4-4 {
        list-style-type: none;
      }
      .lst-kix_list_11-8 > li:before {
        content: "" counter(lst-ctn-kix_list_11-8, lower-roman) ". ";
      }
      .lst-kix_list_12-3 > li {
        counter-increment: lst-ctn-kix_list_12-3;
      }
      ol.lst-kix_list_4-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_2-0.start {
        counter-reset: lst-ctn-kix_list_2-0 0;
      }
      ol.lst-kix_list_4-6 {
        list-style-type: none;
      }
      .lst-kix_list_12-0 > li:before {
        content: "(" counter(lst-ctn-kix_list_12-0, decimal) ") ";
      }
      ol.lst-kix_list_4-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_8-4.start {
        counter-reset: lst-ctn-kix_list_8-4 0;
      }
      .lst-kix_list_1-4 > li:before {
        content: "" counter(lst-ctn-kix_list_1-4, lower-latin) ". ";
      }
      .lst-kix_list_13-0 > li:before {
        content: "(" counter(lst-ctn-kix_list_13-0, decimal) ") ";
      }
      ol.lst-kix_list_3-5.start {
        counter-reset: lst-ctn-kix_list_3-5 0;
      }
      .lst-kix_list_1-6 > li {
        counter-increment: lst-ctn-kix_list_1-6;
      }
      ol.lst-kix_list_13-0.start {
        counter-reset: lst-ctn-kix_list_13-0 0;
      }
      .lst-kix_list_14-4 > li {
        counter-increment: lst-ctn-kix_list_14-4;
      }
      .lst-kix_list_13-4 > li:before {
        content: "" counter(lst-ctn-kix_list_13-4, lower-latin) ". ";
      }
      .lst-kix_list_10-7 > li {
        counter-increment: lst-ctn-kix_list_10-7;
      }
      .lst-kix_list_2-0 > li:before {
        content: "(" counter(lst-ctn-kix_list_2-0, decimal) ") ";
      }
      ol.lst-kix_list_2-1.start {
        counter-reset: lst-ctn-kix_list_2-1 0;
      }
      ol.lst-kix_list_8-3.start {
        counter-reset: lst-ctn-kix_list_8-3 0;
      }
      .lst-kix_list_11-5 > li {
        counter-increment: lst-ctn-kix_list_11-5;
      }
      .lst-kix_list_4-5 > li {
        counter-increment: lst-ctn-kix_list_4-5;
      }
      ol.lst-kix_list_14-5.start {
        counter-reset: lst-ctn-kix_list_14-5 0;
      }
      ol.lst-kix_list_9-8.start {
        counter-reset: lst-ctn-kix_list_9-8 0;
      }
      .lst-kix_list_1-8 > li:before {
        content: "" counter(lst-ctn-kix_list_1-8, lower-roman) ". ";
      }
      .lst-kix_list_12-8 > li:before {
        content: "" counter(lst-ctn-kix_list_12-8, lower-roman) ". ";
      }
      .lst-kix_list_8-2 > li {
        counter-increment: lst-ctn-kix_list_8-2;
      }
      .lst-kix_list_4-1 > li {
        counter-increment: lst-ctn-kix_list_4-1;
      }
      ol.lst-kix_list_12-6.start {
        counter-reset: lst-ctn-kix_list_12-6 0;
      }
      .lst-kix_list_8-1 > li {
        counter-increment: lst-ctn-kix_list_8-1;
      }
      ol.lst-kix_list_8-2.start {
        counter-reset: lst-ctn-kix_list_8-2 0;
      }
      ol.lst-kix_list_3-1.start {
        counter-reset: lst-ctn-kix_list_3-1 0;
      }
      ol.lst-kix_list_6-6.start {
        counter-reset: lst-ctn-kix_list_6-6 0;
      }
      .lst-kix_list_7-0 > li {
        counter-increment: lst-ctn-kix_list_7-0;
      }
      .lst-kix_list_11-0 > li {
        counter-increment: lst-ctn-kix_list_11-0;
      }
      ol.lst-kix_list_1-5.start {
        counter-reset: lst-ctn-kix_list_1-5 0;
      }
      ol.lst-kix_list_9-6.start {
        counter-reset: lst-ctn-kix_list_9-6 0;
      }
      .lst-kix_list_2-3 > li {
        counter-increment: lst-ctn-kix_list_2-3;
      }
      ol.lst-kix_list_4-5.start {
        counter-reset: lst-ctn-kix_list_4-5 0;
      }
      .lst-kix_list_1-2 > li {
        counter-increment: lst-ctn-kix_list_1-2;
      }
      ol.lst-kix_list_14-7.start {
        counter-reset: lst-ctn-kix_list_14-7 0;
      }
      ol.lst-kix_list_11-2.start {
        counter-reset: lst-ctn-kix_list_11-2 0;
      }
      .lst-kix_list_5-2 > li {
        counter-increment: lst-ctn-kix_list_5-2;
      }
      .lst-kix_list_9-2 > li {
        counter-increment: lst-ctn-kix_list_9-2;
      }
      ol.lst-kix_list_8-7.start {
        counter-reset: lst-ctn-kix_list_8-7 0;
      }
      .lst-kix_list_13-2 > li {
        counter-increment: lst-ctn-kix_list_13-2;
      }
      .lst-kix_list_14-3 > li {
        counter-increment: lst-ctn-kix_list_14-3;
      }
      .lst-kix_list_10-3 > li {
        counter-increment: lst-ctn-kix_list_10-3;
      }
      .lst-kix_list_12-1 > li {
        counter-increment: lst-ctn-kix_list_12-1;
      }
      ol.lst-kix_list_1-0.start {
        counter-reset: lst-ctn-kix_list_1-0 0;
      }
      ol.lst-kix_list_13-3.start {
        counter-reset: lst-ctn-kix_list_13-3 0;
      }
      .lst-kix_list_3-0 > li {
        counter-increment: lst-ctn-kix_list_3-0;
      }
      ol.lst-kix_list_4-0.start {
        counter-reset: lst-ctn-kix_list_4-0 0;
      }
      ol.lst-kix_list_11-7.start {
        counter-reset: lst-ctn-kix_list_11-7 0;
      }
      ol.lst-kix_list_14-2.start {
        counter-reset: lst-ctn-kix_list_14-2 0;
      }
      ol.lst-kix_list_2-4.start {
        counter-reset: lst-ctn-kix_list_2-4 0;
      }
      ol.lst-kix_list_1-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_1-4 {
        list-style-type: none;
      }
      .lst-kix_list_2-7 > li:before {
        content: "" counter(lst-ctn-kix_list_2-7, lower-latin) ". ";
      }
      .lst-kix_list_2-7 > li {
        counter-increment: lst-ctn-kix_list_2-7;
      }
      ol.lst-kix_list_1-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_1-6 {
        list-style-type: none;
      }
      ol.lst-kix_list_1-0 {
        list-style-type: none;
      }
      .lst-kix_list_2-5 > li:before {
        content: "" counter(lst-ctn-kix_list_2-5, lower-roman) ". ";
      }
      ol.lst-kix_list_1-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_1-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_10-3.start {
        counter-reset: lst-ctn-kix_list_10-3 0;
      }
      ol.lst-kix_list_9-4.start {
        counter-reset: lst-ctn-kix_list_9-4 0;
      }
      .lst-kix_list_14-6 > li {
        counter-increment: lst-ctn-kix_list_14-6;
      }
      .lst-kix_list_10-1 > li:before {
        content: "" counter(lst-ctn-kix_list_10-1, lower-latin) ". ";
      }
      .lst-kix_list_7-7 > li {
        counter-increment: lst-ctn-kix_list_7-7;
      }
      ol.lst-kix_list_4-3.start {
        counter-reset: lst-ctn-kix_list_4-3 0;
      }
      ol.lst-kix_list_1-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_1-8 {
        list-style-type: none;
      }
      .lst-kix_list_10-7 > li:before {
        content: "" counter(lst-ctn-kix_list_10-7, lower-latin) ". ";
      }
      .lst-kix_list_7-8 > li {
        counter-increment: lst-ctn-kix_list_7-8;
      }
      .lst-kix_list_10-5 > li:before {
        content: "" counter(lst-ctn-kix_list_10-5, lower-roman) ". ";
      }
      ol.lst-kix_list_13-5.start {
        counter-reset: lst-ctn-kix_list_13-5 0;
      }
      .lst-kix_list_10-3 > li:before {
        content: "" counter(lst-ctn-kix_list_10-3, decimal) ". ";
      }
      .lst-kix_list_2-6 > li {
        counter-increment: lst-ctn-kix_list_2-6;
      }
      ol.lst-kix_list_7-3.start {
        counter-reset: lst-ctn-kix_list_7-3 0;
      }
      ol.lst-kix_list_13-8.start {
        counter-reset: lst-ctn-kix_list_13-8 0;
      }
      .lst-kix_list_11-7 > li {
        counter-increment: lst-ctn-kix_list_11-7;
      }
      .lst-kix_list_9-2 > li:before {
        content: "" counter(lst-ctn-kix_list_9-2, lower-roman) ". ";
      }
      ol.lst-kix_list_5-7.start {
        counter-reset: lst-ctn-kix_list_5-7 0;
      }
      ol.lst-kix_list_14-0.start {
        counter-reset: lst-ctn-kix_list_14-0 0;
      }
      .lst-kix_list_12-5 > li {
        counter-increment: lst-ctn-kix_list_12-5;
      }
      .lst-kix_list_5-5 > li {
        counter-increment: lst-ctn-kix_list_5-5;
      }
      .lst-kix_list_9-0 > li:before {
        content: "(" counter(lst-ctn-kix_list_9-0, decimal) ") ";
      }
      .lst-kix_list_3-4 > li {
        counter-increment: lst-ctn-kix_list_3-4;
      }
      ol.lst-kix_list_10-7 {
        list-style-type: none;
      }
      .lst-kix_list_9-6 > li:before {
        content: "" counter(lst-ctn-kix_list_9-6, decimal) ". ";
      }
      ol.lst-kix_list_10-8 {
        list-style-type: none;
      }
      ol.lst-kix_list_10-3 {
        list-style-type: none;
      }
      .lst-kix_list_9-4 > li:before {
        content: "" counter(lst-ctn-kix_list_9-4, lower-latin) ". ";
      }
      ol.lst-kix_list_10-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_10-5 {
        list-style-type: none;
      }
      .lst-kix_list_11-3 > li:before {
        content: "" counter(lst-ctn-kix_list_11-3, decimal) ". ";
      }
      ol.lst-kix_list_10-6 {
        list-style-type: none;
      }
      .lst-kix_list_6-3 > li {
        counter-increment: lst-ctn-kix_list_6-3;
      }
      ol.lst-kix_list_10-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_10-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_1-3.start {
        counter-reset: lst-ctn-kix_list_1-3 0;
      }
      ol.lst-kix_list_10-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_12-1.start {
        counter-reset: lst-ctn-kix_list_12-1 0;
      }
      ol.lst-kix_list_1-2.start {
        counter-reset: lst-ctn-kix_list_1-2 0;
      }
      .lst-kix_list_11-5 > li:before {
        content: "" counter(lst-ctn-kix_list_11-5, lower-roman) ". ";
      }
      ol.lst-kix_list_6-1.start {
        counter-reset: lst-ctn-kix_list_6-1 0;
      }
      .lst-kix_list_9-8 > li:before {
        content: "" counter(lst-ctn-kix_list_9-8, lower-roman) ". ";
      }
      .lst-kix_list_1-1 > li:before {
        content: "" counter(lst-ctn-kix_list_1-1, lower-latin) ". ";
      }
      .lst-kix_list_11-7 > li:before {
        content: "" counter(lst-ctn-kix_list_11-7, lower-latin) ". ";
      }
      .lst-kix_list_8-5 > li {
        counter-increment: lst-ctn-kix_list_8-5;
      }
      .lst-kix_list_1-3 > li:before {
        content: "" counter(lst-ctn-kix_list_1-3, decimal) ". ";
      }
      ol.lst-kix_list_10-5.start {
        counter-reset: lst-ctn-kix_list_10-5 0;
      }
      .lst-kix_list_4-8 > li {
        counter-increment: lst-ctn-kix_list_4-8;
      }
      .lst-kix_list_1-7 > li:before {
        content: "" counter(lst-ctn-kix_list_1-7, lower-latin) ". ";
      }
      ol.lst-kix_list_2-7.start {
        counter-reset: lst-ctn-kix_list_2-7 0;
      }
      .lst-kix_list_1-5 > li:before {
        content: "" counter(lst-ctn-kix_list_1-5, lower-roman) ". ";
      }
      ol.lst-kix_list_9-1.start {
        counter-reset: lst-ctn-kix_list_9-1 0;
      }
      .lst-kix_list_14-7 > li {
        counter-increment: lst-ctn-kix_list_14-7;
      }
      .lst-kix_list_5-6 > li {
        counter-increment: lst-ctn-kix_list_5-6;
      }
      ol.lst-kix_list_7-5.start {
        counter-reset: lst-ctn-kix_list_7-5 0;
      }
      .lst-kix_list_2-1 > li:before {
        content: "" counter(lst-ctn-kix_list_2-1, lower-latin) ". ";
      }
      .lst-kix_list_2-3 > li:before {
        content: "" counter(lst-ctn-kix_list_2-3, decimal) ". ";
      }
      .lst-kix_list_11-8 > li {
        counter-increment: lst-ctn-kix_list_11-8;
      }
      .lst-kix_list_3-1 > li {
        counter-increment: lst-ctn-kix_list_3-1;
      }
      .lst-kix_list_9-1 > li {
        counter-increment: lst-ctn-kix_list_9-1;
      }
      ol.lst-kix_list_7-7.start {
        counter-reset: lst-ctn-kix_list_7-7 0;
      }
      .lst-kix_list_3-1 > li:before {
        content: "" counter(lst-ctn-kix_list_3-1, lower-latin) ". ";
      }
      .lst-kix_list_3-2 > li:before {
        content: "" counter(lst-ctn-kix_list_3-2, lower-roman) ". ";
      }
      .lst-kix_list_14-0 > li {
        counter-increment: lst-ctn-kix_list_14-0;
      }
      .lst-kix_list_8-1 > li:before {
        content: "" counter(lst-ctn-kix_list_8-1, lower-latin) ". ";
      }
      ol.lst-kix_list_1-8.start {
        counter-reset: lst-ctn-kix_list_1-8 0;
      }
      .lst-kix_list_8-2 > li:before {
        content: "" counter(lst-ctn-kix_list_8-2, lower-roman) ". ";
      }
      .lst-kix_list_6-0 > li {
        counter-increment: lst-ctn-kix_list_6-0;
      }
      .lst-kix_list_3-5 > li:before {
        content: "" counter(lst-ctn-kix_list_3-5, lower-roman) ". ";
      }
      .lst-kix_list_12-0 > li {
        counter-increment: lst-ctn-kix_list_12-0;
      }
      ol.lst-kix_list_12-3.start {
        counter-reset: lst-ctn-kix_list_12-3 0;
      }
      ol.lst-kix_list_11-5.start {
        counter-reset: lst-ctn-kix_list_11-5 0;
      }
      .lst-kix_list_8-5 > li:before {
        content: "" counter(lst-ctn-kix_list_8-5, lower-roman) ". ";
      }
      .lst-kix_list_11-1 > li {
        counter-increment: lst-ctn-kix_list_11-1;
      }
      .lst-kix_list_8-6 > li:before {
        content: "" counter(lst-ctn-kix_list_8-6, decimal) ". ";
      }
      .lst-kix_list_2-0 > li {
        counter-increment: lst-ctn-kix_list_2-0;
      }
      .lst-kix_list_3-6 > li:before {
        content: "" counter(lst-ctn-kix_list_3-6, decimal) ". ";
      }
      ol.lst-kix_list_5-0.start {
        counter-reset: lst-ctn-kix_list_5-0 0;
      }
      .lst-kix_list_11-2 > li:before {
        content: "" counter(lst-ctn-kix_list_11-2, lower-roman) ". ";
      }
      ol.lst-kix_list_4-2.start {
        counter-reset: lst-ctn-kix_list_4-2 0;
      }
      ol.lst-kix_list_11-6.start {
        counter-reset: lst-ctn-kix_list_11-6 0;
      }
      ol.lst-kix_list_12-4.start {
        counter-reset: lst-ctn-kix_list_12-4 0;
      }
      ol.lst-kix_list_10-1.start {
        counter-reset: lst-ctn-kix_list_10-1 0;
      }
      .lst-kix_list_4-4 > li {
        counter-increment: lst-ctn-kix_list_4-4;
      }
      ol.lst-kix_list_6-4.start {
        counter-reset: lst-ctn-kix_list_6-4 0;
      }
      ol.lst-kix_list_5-6.start {
        counter-reset: lst-ctn-kix_list_5-6 0;
      }
      ol.lst-kix_list_4-1.start {
        counter-reset: lst-ctn-kix_list_4-1 0;
      }
      .lst-kix_list_7-3 > li {
        counter-increment: lst-ctn-kix_list_7-3;
      }
      ol.lst-kix_list_7-8.start {
        counter-reset: lst-ctn-kix_list_7-8 0;
      }
      .lst-kix_list_12-4 > li {
        counter-increment: lst-ctn-kix_list_12-4;
      }
      ol.lst-kix_list_11-0.start {
        counter-reset: lst-ctn-kix_list_11-0 0;
      }
      .lst-kix_list_3-3 > li {
        counter-increment: lst-ctn-kix_list_3-3;
      }
      ol.lst-kix_list_6-3.start {
        counter-reset: lst-ctn-kix_list_6-3 0;
      }
      ol.lst-kix_list_10-2.start {
        counter-reset: lst-ctn-kix_list_10-2 0;
      }
      .lst-kix_list_12-7 > li {
        counter-increment: lst-ctn-kix_list_12-7;
      }
      ol.lst-kix_list_5-5.start {
        counter-reset: lst-ctn-kix_list_5-5 0;
      }
      .lst-kix_list_6-2 > li {
        counter-increment: lst-ctn-kix_list_6-2;
      }
      .lst-kix_list_2-6 > li:before {
        content: "" counter(lst-ctn-kix_list_2-6, decimal) ". ";
      }
      .lst-kix_list_14-5 > li {
        counter-increment: lst-ctn-kix_list_14-5;
      }
      .lst-kix_list_7-1 > li:before {
        content: "" counter(lst-ctn-kix_list_7-1, lower-latin) ". ";
      }
      .lst-kix_list_7-5 > li:before {
        content: "" counter(lst-ctn-kix_list_7-5, lower-roman) ". ";
      }
      .lst-kix_list_13-5 > li {
        counter-increment: lst-ctn-kix_list_13-5;
      }
      .lst-kix_list_9-6 > li {
        counter-increment: lst-ctn-kix_list_9-6;
      }
      ol.lst-kix_list_5-4.start {
        counter-reset: lst-ctn-kix_list_5-4 0;
      }
      ol.lst-kix_list_11-1.start {
        counter-reset: lst-ctn-kix_list_11-1 0;
      }
      .lst-kix_list_13-6 > li:before {
        content: "" counter(lst-ctn-kix_list_13-6, decimal) ". ";
      }
      ol.lst-kix_list_5-1.start {
        counter-reset: lst-ctn-kix_list_5-1 0;
      }
      .lst-kix_list_6-7 > li {
        counter-increment: lst-ctn-kix_list_6-7;
      }
      .lst-kix_list_10-6 > li {
        counter-increment: lst-ctn-kix_list_10-6;
      }
      .lst-kix_list_11-6 > li {
        counter-increment: lst-ctn-kix_list_11-6;
      }
      .lst-kix_list_1-7 > li {
        counter-increment: lst-ctn-kix_list_1-7;
      }
      ol.lst-kix_list_10-0.start {
        counter-reset: lst-ctn-kix_list_10-0 0;
      }
      .lst-kix_list_7-5 > li {
        counter-increment: lst-ctn-kix_list_7-5;
      }
      .lst-kix_list_11-4 > li {
        counter-increment: lst-ctn-kix_list_11-4;
      }
      .lst-kix_list_3-8 > li {
        counter-increment: lst-ctn-kix_list_3-8;
      }
      ol.lst-kix_list_6-8.start {
        counter-reset: lst-ctn-kix_list_6-8 0;
      }
      .lst-kix_list_10-2 > li:before {
        content: "" counter(lst-ctn-kix_list_10-2, lower-roman) ". ";
      }
      .lst-kix_list_4-6 > li {
        counter-increment: lst-ctn-kix_list_4-6;
      }
      .lst-kix_list_13-7 > li {
        counter-increment: lst-ctn-kix_list_13-7;
      }
      ol.lst-kix_list_1-7.start {
        counter-reset: lst-ctn-kix_list_1-7 0;
      }
      .lst-kix_list_1-5 > li {
        counter-increment: lst-ctn-kix_list_1-5;
      }
      ol.lst-kix_list_6-5.start {
        counter-reset: lst-ctn-kix_list_6-5 0;
      }
      .lst-kix_list_4-2 > li:before {
        content: "" counter(lst-ctn-kix_list_4-2, lower-roman) ". ";
      }
      .lst-kix_list_4-6 > li:before {
        content: "" counter(lst-ctn-kix_list_4-6, decimal) ". ";
      }
      .lst-kix_list_10-8 > li {
        counter-increment: lst-ctn-kix_list_10-8;
      }
      .lst-kix_list_10-6 > li:before {
        content: "" counter(lst-ctn-kix_list_10-6, decimal) ". ";
      }
      .lst-kix_list_9-1 > li:before {
        content: "" counter(lst-ctn-kix_list_9-1, lower-latin) ". ";
      }
      ol.lst-kix_list_12-7.start {
        counter-reset: lst-ctn-kix_list_12-7 0;
      }
      ol.lst-kix_list_6-7.start {
        counter-reset: lst-ctn-kix_list_6-7 0;
      }
      .lst-kix_list_12-2 > li {
        counter-increment: lst-ctn-kix_list_12-2;
      }
      .lst-kix_list_9-5 > li:before {
        content: "" counter(lst-ctn-kix_list_9-5, lower-roman) ". ";
      }
      .lst-kix_list_12-2 > li:before {
        content: "" counter(lst-ctn-kix_list_12-2, lower-roman) ". ";
      }
      ol.lst-kix_list_12-8.start {
        counter-reset: lst-ctn-kix_list_12-8 0;
      }
      .lst-kix_list_11-6 > li:before {
        content: "" counter(lst-ctn-kix_list_11-6, decimal) ". ";
      }
      ol.lst-kix_list_11-3.start {
        counter-reset: lst-ctn-kix_list_11-3 0;
      }
      .lst-kix_list_1-2 > li:before {
        content: "" counter(lst-ctn-kix_list_1-2, lower-roman) ". ";
      }
      .lst-kix_list_10-1 > li {
        counter-increment: lst-ctn-kix_list_10-1;
      }
      .lst-kix_list_1-0 > li {
        counter-increment: lst-ctn-kix_list_1-0;
      }
      .lst-kix_list_8-8 > li {
        counter-increment: lst-ctn-kix_list_8-8;
      }
      .lst-kix_list_1-6 > li:before {
        content: "" counter(lst-ctn-kix_list_1-6, decimal) ". ";
      }
      .lst-kix_list_12-6 > li:before {
        content: "" counter(lst-ctn-kix_list_12-6, decimal) ". ";
      }
      ol.lst-kix_list_11-4.start {
        counter-reset: lst-ctn-kix_list_11-4 0;
      }
      .lst-kix_list_2-2 > li:before {
        content: "" counter(lst-ctn-kix_list_2-2, lower-roman) ". ";
      }
      .lst-kix_list_13-2 > li:before {
        content: "" counter(lst-ctn-kix_list_13-2, lower-roman) ". ";
      }
      ol.lst-kix_list_5-2.start {
        counter-reset: lst-ctn-kix_list_5-2 0;
      }
      ol {
        margin: 0;
        padding: 0;
      }
      table td,
      table th {
        padding: 0;
      }
      .c9 {
        border-right-style: solid;
        padding-top: 0pt;
        border-top-width: 0pt;
        border-bottom-color: #000000;
        border-right-width: 0pt;
        padding-left: 0pt;
        border-left-color: #000000;
        padding-bottom: 0pt;
        line-height: 1.0791666666666666;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        border-top-color: #000000;
        border-bottom-style: solid;
        orphans: 2;
        widows: 2;
        text-align: left;
        padding-right: 0pt;
        height: 10pt;
      }
      .c3 {
        border-right-style: solid;
        padding-top: 0pt;
        border-top-width: 0pt;
        border-bottom-color: #000000;
        border-right-width: 0pt;
        padding-left: 0pt;
        border-left-color: #000000;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        border-top-color: #000000;
        border-bottom-style: solid;
        orphans: 2;
        widows: 2;
        text-align: center;
        padding-right: 0pt;
      }
      .c1 {
        border-right-style: solid;
        padding-top: 0pt;
        border-top-width: 0pt;
        border-bottom-color: #000000;
        border-right-width: 0pt;
        padding-left: 0pt;
        border-left-color: #000000;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        border-top-color: #000000;
        border-bottom-style: solid;
        orphans: 2;
        widows: 2;
        text-align: justify;
        padding-right: 0pt;
      }
      .c15 {
        border-right-style: solid;
        padding: 0pt 3.5pt 0pt 3.5pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        border-top-color: #000000;
        border-bottom-style: solid;
      }
      .c17 {
        color: #000000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Arial";
        font-style: normal;
      }
      .c0 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10pt;
        font-family: "Arial";
        font-style: normal;
      }
      .c2 {
        color: #000000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10pt;
        font-family: "Arial";
        font-style: normal;
      }
      .c7 {
        padding-top: 6pt;
        padding-bottom: 6pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: left;
        height: 10pt;
      }
      .c6 {
        padding-top: 6pt;
        padding-bottom: 6pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: center;
        height: 10pt;
      }
      .c8 {
        padding-top: 6pt;
        padding-bottom: 6pt;
        line-height: 1.1500000000000001;
        orphans: 2;
        widows: 2;
        text-align: justify;
      }
      .c4 {
        padding-top: 6pt;
        padding-bottom: 6pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
      }
      .c5 {
        border-spacing: 0;
        border-collapse: collapse;
        margin-right: auto;
      }
      .c16 {
        background-color: #ffffff;
        // padding: 72pt 72pt 72pt 72pt;
      }
      .c12 {
        margin-left: 72pt;
      }
      .c10 {
        margin-left: 31.7pt;
      }
      .c14 {
        height: 0pt;
      }
      .c13 {
        height: 10pt;
      }
      .c11 {
        page-break-after: avoid;
      }
      .title {
        padding-top: 24pt;
        color: #000000;
        font-weight: 700;
        font-size: 36pt;
        padding-bottom: 6pt;
        font-family: "Arial";
        line-height: 1;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      .subtitle {
        padding-top: 18pt;
        color: #666666;
        font-size: 24pt;
        padding-bottom: 4pt;
        font-family: "Georgia";
        line-height: 1;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      li {
        color: #000000;
        font-size: 10pt;
        font-family: "Arial";
      }
      p {
        margin: 0;
        color: #000000;
        font-size: 10pt;
        font-family: "Arial";
      }
      h1 {
        padding-top: 11.9pt;
        color: #000000;
        font-weight: 700;
        font-size: 16pt;
        padding-bottom: 3.1pt;
        font-family: "Helvetica Neue";
        line-height: 1;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h2 {
        padding-top: 11.9pt;
        color: #000000;
        font-weight: 700;
        font-size: 14pt;
        padding-bottom: 3.1pt;
        font-family: "Helvetica Neue";
        line-height: 1;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h3 {
        padding-top: 11.9pt;
        color: #000000;
        font-weight: 700;
        font-size: 13pt;
        padding-bottom: 3.1pt;
        font-family: "Helvetica Neue";
        line-height: 1;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h4 {
        padding-top: 11.9pt;
        color: #000000;
        font-weight: 700;
        font-size: 14pt;
        padding-bottom: 3.1pt;
        font-family: "Times";
        line-height: 1;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h5 {
        padding-top: 11.9pt;
        color: #000000;
        font-weight: 700;
        font-size: 13pt;
        padding-bottom: 3.1pt;
        font-family: "Times";
        line-height: 1;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h6 {
        padding-top: 11.9pt;
        color: #000000;
        font-weight: 700;
        font-size: 11pt;
        padding-bottom: 3.1pt;
        font-family: "Times";
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
    }
  }
  .hertenberger {
    .tnc {
      //   @import url("https://themes.googleusercontent.com/fonts/css?kit=r3oQQBWBLofdqMg7XagtpouATS5UIGs1Tg1LXDS_dFdbV0WvE1cEyAoIq5yYZlSc");
      ol.lst-kix_list_7-0 {
        list-style-type: none;
      }
      .lst-kix_list_14-1 > li:before {
        content: "" counter(lst-ctn-kix_list_14-1, lower-latin) ". ";
      }
      .lst-kix_list_14-3 > li:before {
        content: "" counter(lst-ctn-kix_list_14-3, decimal) ". ";
      }
      .lst-kix_list_2-1 > li {
        counter-increment: lst-ctn-kix_list_2-1;
      }
      ol.lst-kix_list_9-0.start {
        counter-reset: lst-ctn-kix_list_9-0 0;
      }
      .lst-kix_list_14-0 > li:before {
        content: "(" counter(lst-ctn-kix_list_14-0, decimal) ") ";
      }
      .lst-kix_list_14-4 > li:before {
        content: "" counter(lst-ctn-kix_list_14-4, lower-latin) ". ";
      }
      .lst-kix_list_6-1 > li {
        counter-increment: lst-ctn-kix_list_6-1;
      }
      .lst-kix_list_14-5 > li:before {
        content: "" counter(lst-ctn-kix_list_14-5, lower-roman) ". ";
      }
      .lst-kix_list_14-7 > li:before {
        content: "" counter(lst-ctn-kix_list_14-7, lower-latin) ". ";
      }
      ol.lst-kix_list_13-4.start {
        counter-reset: lst-ctn-kix_list_13-4 0;
      }
      .lst-kix_list_14-6 > li:before {
        content: "" counter(lst-ctn-kix_list_14-6, decimal) ". ";
      }
      .lst-kix_list_13-0 > li {
        counter-increment: lst-ctn-kix_list_13-0;
      }
      ol.lst-kix_list_7-4.start {
        counter-reset: lst-ctn-kix_list_7-4 0;
      }
      .lst-kix_list_5-0 > li {
        counter-increment: lst-ctn-kix_list_5-0;
      }
      .lst-kix_list_9-0 > li {
        counter-increment: lst-ctn-kix_list_9-0;
      }
      ol.lst-kix_list_2-3.start {
        counter-reset: lst-ctn-kix_list_2-3 0;
      }
      ol.lst-kix_list_7-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_7-6 {
        list-style-type: none;
      }
      ol.lst-kix_list_7-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_7-8 {
        list-style-type: none;
      }
      ol.lst-kix_list_7-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_7-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_11-8.start {
        counter-reset: lst-ctn-kix_list_11-8 0;
      }
      ol.lst-kix_list_7-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_7-4 {
        list-style-type: none;
      }
      .lst-kix_list_14-2 > li:before {
        content: "" counter(lst-ctn-kix_list_14-2, lower-roman) ". ";
      }
      ol.lst-kix_list_5-3.start {
        counter-reset: lst-ctn-kix_list_5-3 0;
      }
      ol.lst-kix_list_12-0.start {
        counter-reset: lst-ctn-kix_list_12-0 0;
      }
      .lst-kix_list_4-3 > li {
        counter-increment: lst-ctn-kix_list_4-3;
      }
      ol.lst-kix_list_3-7.start {
        counter-reset: lst-ctn-kix_list_3-7 0;
      }
      .lst-kix_list_14-8 > li:before {
        content: "" counter(lst-ctn-kix_list_14-8, lower-roman) ". ";
      }
      ol.lst-kix_list_8-8.start {
        counter-reset: lst-ctn-kix_list_8-8 0;
      }
      .lst-kix_list_3-2 > li {
        counter-increment: lst-ctn-kix_list_3-2;
      }
      ol.lst-kix_list_10-4.start {
        counter-reset: lst-ctn-kix_list_10-4 0;
      }
      .lst-kix_list_7-2 > li {
        counter-increment: lst-ctn-kix_list_7-2;
      }
      .lst-kix_list_5-0 > li:before {
        content: "(" counter(lst-ctn-kix_list_5-0, decimal) ") ";
      }
      ol.lst-kix_list_6-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_6-1 {
        list-style-type: none;
      }
      .lst-kix_list_14-8 > li {
        counter-increment: lst-ctn-kix_list_14-8;
      }
      .lst-kix_list_5-4 > li {
        counter-increment: lst-ctn-kix_list_5-4;
      }
      .lst-kix_list_1-4 > li {
        counter-increment: lst-ctn-kix_list_1-4;
      }
      ol.lst-kix_list_1-6.start {
        counter-reset: lst-ctn-kix_list_1-6 0;
      }
      .lst-kix_list_5-3 > li:before {
        content: "" counter(lst-ctn-kix_list_5-3, decimal) ". ";
      }
      ol.lst-kix_list_9-5.start {
        counter-reset: lst-ctn-kix_list_9-5 0;
      }
      .lst-kix_list_5-2 > li:before {
        content: "" counter(lst-ctn-kix_list_5-2, lower-roman) ". ";
      }
      .lst-kix_list_8-3 > li {
        counter-increment: lst-ctn-kix_list_8-3;
      }
      .lst-kix_list_5-1 > li:before {
        content: "" counter(lst-ctn-kix_list_5-1, lower-latin) ". ";
      }
      .lst-kix_list_5-7 > li:before {
        content: "" counter(lst-ctn-kix_list_5-7, lower-latin) ". ";
      }
      .lst-kix_list_5-6 > li:before {
        content: "" counter(lst-ctn-kix_list_5-6, decimal) ". ";
      }
      .lst-kix_list_5-8 > li:before {
        content: "" counter(lst-ctn-kix_list_5-8, lower-roman) ". ";
      }
      .lst-kix_list_9-4 > li {
        counter-increment: lst-ctn-kix_list_9-4;
      }
      ol.lst-kix_list_6-6 {
        list-style-type: none;
      }
      ol.lst-kix_list_6-7 {
        list-style-type: none;
      }
      .lst-kix_list_5-4 > li:before {
        content: "" counter(lst-ctn-kix_list_5-4, lower-latin) ". ";
      }
      ol.lst-kix_list_6-8 {
        list-style-type: none;
      }
      .lst-kix_list_5-5 > li:before {
        content: "" counter(lst-ctn-kix_list_5-5, lower-roman) ". ";
      }
      ol.lst-kix_list_6-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_6-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_6-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_6-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_14-1.start {
        counter-reset: lst-ctn-kix_list_14-1 0;
      }
      ol.lst-kix_list_12-5.start {
        counter-reset: lst-ctn-kix_list_12-5 0;
      }
      .lst-kix_list_6-1 > li:before {
        content: "" counter(lst-ctn-kix_list_6-1, lower-latin) ". ";
      }
      .lst-kix_list_6-3 > li:before {
        content: "" counter(lst-ctn-kix_list_6-3, decimal) ". ";
      }
      .lst-kix_list_6-5 > li {
        counter-increment: lst-ctn-kix_list_6-5;
      }
      .lst-kix_list_6-8 > li {
        counter-increment: lst-ctn-kix_list_6-8;
      }
      .lst-kix_list_6-0 > li:before {
        content: "(" counter(lst-ctn-kix_list_6-0, decimal) ") ";
      }
      .lst-kix_list_6-4 > li:before {
        content: "" counter(lst-ctn-kix_list_6-4, lower-latin) ". ";
      }
      ol.lst-kix_list_14-8.start {
        counter-reset: lst-ctn-kix_list_14-8 0;
      }
      .lst-kix_list_3-6 > li {
        counter-increment: lst-ctn-kix_list_3-6;
      }
      .lst-kix_list_6-2 > li:before {
        content: "" counter(lst-ctn-kix_list_6-2, lower-roman) ". ";
      }
      .lst-kix_list_2-5 > li {
        counter-increment: lst-ctn-kix_list_2-5;
      }
      .lst-kix_list_2-8 > li {
        counter-increment: lst-ctn-kix_list_2-8;
      }
      ol.lst-kix_list_3-2.start {
        counter-reset: lst-ctn-kix_list_3-2 0;
      }
      .lst-kix_list_6-8 > li:before {
        content: "" counter(lst-ctn-kix_list_6-8, lower-roman) ". ";
      }
      .lst-kix_list_6-5 > li:before {
        content: "" counter(lst-ctn-kix_list_6-5, lower-roman) ". ";
      }
      .lst-kix_list_6-7 > li:before {
        content: "" counter(lst-ctn-kix_list_6-7, lower-latin) ". ";
      }
      .lst-kix_list_6-6 > li:before {
        content: "" counter(lst-ctn-kix_list_6-6, decimal) ". ";
      }
      ol.lst-kix_list_10-6.start {
        counter-reset: lst-ctn-kix_list_10-6 0;
      }
      .lst-kix_list_7-4 > li:before {
        content: "" counter(lst-ctn-kix_list_7-4, lower-latin) ". ";
      }
      .lst-kix_list_7-6 > li:before {
        content: "" counter(lst-ctn-kix_list_7-6, decimal) ". ";
      }
      ol.lst-kix_list_6-2.start {
        counter-reset: lst-ctn-kix_list_6-2 0;
      }
      .lst-kix_list_7-2 > li:before {
        content: "" counter(lst-ctn-kix_list_7-2, lower-roman) ". ";
      }
      .lst-kix_list_7-6 > li {
        counter-increment: lst-ctn-kix_list_7-6;
      }
      .lst-kix_list_8-6 > li {
        counter-increment: lst-ctn-kix_list_8-6;
      }
      .lst-kix_list_12-6 > li {
        counter-increment: lst-ctn-kix_list_12-6;
      }
      ol.lst-kix_list_4-6.start {
        counter-reset: lst-ctn-kix_list_4-6 0;
      }
      ol.lst-kix_list_9-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_9-8 {
        list-style-type: none;
      }
      .lst-kix_list_13-7 > li:before {
        content: "" counter(lst-ctn-kix_list_13-7, lower-latin) ". ";
      }
      ol.lst-kix_list_3-0.start {
        counter-reset: lst-ctn-kix_list_3-0 0;
      }
      ol.lst-kix_list_9-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_9-4 {
        list-style-type: none;
      }
      .lst-kix_list_5-7 > li {
        counter-increment: lst-ctn-kix_list_5-7;
      }
      ol.lst-kix_list_9-5 {
        list-style-type: none;
      }
      .lst-kix_list_7-8 > li:before {
        content: "" counter(lst-ctn-kix_list_7-8, lower-roman) ". ";
      }
      ol.lst-kix_list_9-6 {
        list-style-type: none;
      }
      .lst-kix_list_4-7 > li {
        counter-increment: lst-ctn-kix_list_4-7;
      }
      ol.lst-kix_list_9-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_9-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_9-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_2-5.start {
        counter-reset: lst-ctn-kix_list_2-5 0;
      }
      .lst-kix_list_9-8 > li {
        counter-increment: lst-ctn-kix_list_9-8;
      }
      .lst-kix_list_13-4 > li {
        counter-increment: lst-ctn-kix_list_13-4;
      }
      .lst-kix_list_4-1 > li:before {
        content: "" counter(lst-ctn-kix_list_4-1, lower-latin) ". ";
      }
      .lst-kix_list_4-3 > li:before {
        content: "" counter(lst-ctn-kix_list_4-3, decimal) ". ";
      }
      .lst-kix_list_4-5 > li:before {
        content: "" counter(lst-ctn-kix_list_4-5, lower-roman) ". ";
      }
      .lst-kix_list_1-8 > li {
        counter-increment: lst-ctn-kix_list_1-8;
      }
      .lst-kix_list_10-5 > li {
        counter-increment: lst-ctn-kix_list_10-5;
      }
      ol.lst-kix_list_1-4.start {
        counter-reset: lst-ctn-kix_list_1-4 0;
      }
      .lst-kix_list_3-5 > li {
        counter-increment: lst-ctn-kix_list_3-5;
      }
      ol.lst-kix_list_1-1.start {
        counter-reset: lst-ctn-kix_list_1-1 0;
      }
      ol.lst-kix_list_4-4.start {
        counter-reset: lst-ctn-kix_list_4-4 0;
      }
      .lst-kix_list_6-4 > li {
        counter-increment: lst-ctn-kix_list_6-4;
      }
      ol.lst-kix_list_9-2.start {
        counter-reset: lst-ctn-kix_list_9-2 0;
      }
      .lst-kix_list_9-3 > li {
        counter-increment: lst-ctn-kix_list_9-3;
      }
      .lst-kix_list_11-2 > li {
        counter-increment: lst-ctn-kix_list_11-2;
      }
      ol.lst-kix_list_2-8.start {
        counter-reset: lst-ctn-kix_list_2-8 0;
      }
      ol.lst-kix_list_8-8 {
        list-style-type: none;
      }
      .lst-kix_list_12-3 > li:before {
        content: "" counter(lst-ctn-kix_list_12-3, decimal) ". ";
      }
      ol.lst-kix_list_7-6.start {
        counter-reset: lst-ctn-kix_list_7-6 0;
      }
      ol.lst-kix_list_8-4 {
        list-style-type: none;
      }
      .lst-kix_list_12-1 > li:before {
        content: "" counter(lst-ctn-kix_list_12-1, lower-latin) ". ";
      }
      ol.lst-kix_list_8-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_8-6 {
        list-style-type: none;
      }
      ol.lst-kix_list_8-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_8-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_8-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_8-2 {
        list-style-type: none;
      }
      .lst-kix_list_13-3 > li {
        counter-increment: lst-ctn-kix_list_13-3;
      }
      ol.lst-kix_list_13-6.start {
        counter-reset: lst-ctn-kix_list_13-6 0;
      }
      ol.lst-kix_list_8-3 {
        list-style-type: none;
      }
      .lst-kix_list_10-4 > li {
        counter-increment: lst-ctn-kix_list_10-4;
      }
      .lst-kix_list_14-1 > li {
        counter-increment: lst-ctn-kix_list_14-1;
      }
      .lst-kix_list_13-3 > li:before {
        content: "" counter(lst-ctn-kix_list_13-3, decimal) ". ";
      }
      ol.lst-kix_list_5-8.start {
        counter-reset: lst-ctn-kix_list_5-8 0;
      }
      .lst-kix_list_1-3 > li {
        counter-increment: lst-ctn-kix_list_1-3;
      }
      .lst-kix_list_13-5 > li:before {
        content: "" counter(lst-ctn-kix_list_13-5, lower-roman) ". ";
      }
      .lst-kix_list_12-5 > li:before {
        content: "" counter(lst-ctn-kix_list_12-5, lower-roman) ". ";
      }
      ol.lst-kix_list_13-7.start {
        counter-reset: lst-ctn-kix_list_13-7 0;
      }
      ol.lst-kix_list_12-2.start {
        counter-reset: lst-ctn-kix_list_12-2 0;
      }
      .lst-kix_list_12-7 > li:before {
        content: "" counter(lst-ctn-kix_list_12-7, lower-latin) ". ";
      }
      ol.lst-kix_list_6-0.start {
        counter-reset: lst-ctn-kix_list_6-0 0;
      }
      .lst-kix_list_4-2 > li {
        counter-increment: lst-ctn-kix_list_4-2;
      }
      .lst-kix_list_13-1 > li:before {
        content: "" counter(lst-ctn-kix_list_13-1, lower-latin) ". ";
      }
      ol.lst-kix_list_3-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_3-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_3-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_3-4.start {
        counter-reset: lst-ctn-kix_list_3-4 0;
      }
      .lst-kix_list_5-1 > li {
        counter-increment: lst-ctn-kix_list_5-1;
      }
      ol.lst-kix_list_3-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_3-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_11-6 {
        list-style-type: none;
      }
      ol.lst-kix_list_11-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_11-8 {
        list-style-type: none;
      }
      .lst-kix_list_1-1 > li {
        counter-increment: lst-ctn-kix_list_1-1;
      }
      .lst-kix_list_7-1 > li {
        counter-increment: lst-ctn-kix_list_7-1;
      }
      ol.lst-kix_list_11-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_11-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_2-6.start {
        counter-reset: lst-ctn-kix_list_2-6 0;
      }
      .lst-kix_list_3-0 > li:before {
        content: "(" counter(lst-ctn-kix_list_3-0, decimal) ") ";
      }
      ol.lst-kix_list_11-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_11-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_13-1.start {
        counter-reset: lst-ctn-kix_list_13-1 0;
      }
      ol.lst-kix_list_11-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_11-1 {
        list-style-type: none;
      }
      .lst-kix_list_4-0 > li {
        counter-increment: lst-ctn-kix_list_4-0;
      }
      .lst-kix_list_8-0 > li {
        counter-increment: lst-ctn-kix_list_8-0;
      }
      .lst-kix_list_10-0 > li {
        counter-increment: lst-ctn-kix_list_10-0;
      }
      .lst-kix_list_3-4 > li:before {
        content: "" counter(lst-ctn-kix_list_3-4, lower-latin) ". ";
      }
      .lst-kix_list_3-3 > li:before {
        content: "" counter(lst-ctn-kix_list_3-3, decimal) ". ";
      }
      ol.lst-kix_list_3-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_3-6 {
        list-style-type: none;
      }
      .lst-kix_list_8-0 > li:before {
        content: "(" counter(lst-ctn-kix_list_8-0, decimal) ") ";
      }
      ol.lst-kix_list_3-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_3-8 {
        list-style-type: none;
      }
      .lst-kix_list_8-7 > li:before {
        content: "" counter(lst-ctn-kix_list_8-7, lower-latin) ". ";
      }
      .lst-kix_list_3-8 > li:before {
        content: "" counter(lst-ctn-kix_list_3-8, lower-roman) ". ";
      }
      ol.lst-kix_list_10-7.start {
        counter-reset: lst-ctn-kix_list_10-7 0;
      }
      .lst-kix_list_13-1 > li {
        counter-increment: lst-ctn-kix_list_13-1;
      }
      .lst-kix_list_8-3 > li:before {
        content: "" counter(lst-ctn-kix_list_8-3, decimal) ". ";
      }
      .lst-kix_list_3-7 > li:before {
        content: "" counter(lst-ctn-kix_list_3-7, lower-latin) ". ";
      }
      .lst-kix_list_8-4 > li:before {
        content: "" counter(lst-ctn-kix_list_8-4, lower-latin) ". ";
      }
      .lst-kix_list_10-2 > li {
        counter-increment: lst-ctn-kix_list_10-2;
      }
      ol.lst-kix_list_8-5.start {
        counter-reset: lst-ctn-kix_list_8-5 0;
      }
      .lst-kix_list_11-1 > li:before {
        content: "" counter(lst-ctn-kix_list_11-1, lower-latin) ". ";
      }
      .lst-kix_list_11-0 > li:before {
        content: "(" counter(lst-ctn-kix_list_11-0, decimal) ") ";
      }
      ol.lst-kix_list_9-3.start {
        counter-reset: lst-ctn-kix_list_9-3 0;
      }
      .lst-kix_list_8-8 > li:before {
        content: "" counter(lst-ctn-kix_list_8-8, lower-roman) ". ";
      }
      ol.lst-kix_list_2-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_2-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_2-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_7-2.start {
        counter-reset: lst-ctn-kix_list_7-2 0;
      }
      ol.lst-kix_list_2-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_2-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_2-1 {
        list-style-type: none;
      }
      .lst-kix_list_4-8 > li:before {
        content: "" counter(lst-ctn-kix_list_4-8, lower-roman) ". ";
      }
      ol.lst-kix_list_12-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_12-6 {
        list-style-type: none;
      }
      .lst-kix_list_4-7 > li:before {
        content: "" counter(lst-ctn-kix_list_4-7, lower-latin) ". ";
      }
      ol.lst-kix_list_12-7 {
        list-style-type: none;
      }
      .lst-kix_list_14-2 > li {
        counter-increment: lst-ctn-kix_list_14-2;
      }
      ol.lst-kix_list_12-8 {
        list-style-type: none;
      }
      ol.lst-kix_list_12-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_12-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_12-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_12-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_12-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_4-8.start {
        counter-reset: lst-ctn-kix_list_4-8 0;
      }
      .lst-kix_list_8-4 > li {
        counter-increment: lst-ctn-kix_list_8-4;
      }
      ol.lst-kix_list_3-3.start {
        counter-reset: lst-ctn-kix_list_3-3 0;
      }
      ol.lst-kix_list_10-8.start {
        counter-reset: lst-ctn-kix_list_10-8 0;
      }
      ol.lst-kix_list_2-6 {
        list-style-type: none;
      }
      ol.lst-kix_list_2-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_2-8 {
        list-style-type: none;
      }
      .lst-kix_list_11-3 > li {
        counter-increment: lst-ctn-kix_list_11-3;
      }
      ol.lst-kix_list_7-1.start {
        counter-reset: lst-ctn-kix_list_7-1 0;
      }
      ol.lst-kix_list_8-6.start {
        counter-reset: lst-ctn-kix_list_8-6 0;
      }
      ol.lst-kix_list_8-0.start {
        counter-reset: lst-ctn-kix_list_8-0 0;
      }
      .lst-kix_list_7-0 > li:before {
        content: "(" counter(lst-ctn-kix_list_7-0, decimal) ") ";
      }
      .lst-kix_list_13-8 > li {
        counter-increment: lst-ctn-kix_list_13-8;
      }
      .lst-kix_list_2-2 > li {
        counter-increment: lst-ctn-kix_list_2-2;
      }
      ol.lst-kix_list_4-7.start {
        counter-reset: lst-ctn-kix_list_4-7 0;
      }
      ol.lst-kix_list_5-0 {
        list-style-type: none;
      }
      .lst-kix_list_3-7 > li {
        counter-increment: lst-ctn-kix_list_3-7;
      }
      ol.lst-kix_list_5-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_9-7.start {
        counter-reset: lst-ctn-kix_list_9-7 0;
      }
      ol.lst-kix_list_5-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_13-8 {
        list-style-type: none;
      }
      .lst-kix_list_2-4 > li:before {
        content: "" counter(lst-ctn-kix_list_2-4, lower-latin) ". ";
      }
      .lst-kix_list_2-8 > li:before {
        content: "" counter(lst-ctn-kix_list_2-8, lower-roman) ". ";
      }
      ol.lst-kix_list_13-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_13-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_13-6 {
        list-style-type: none;
      }
      ol.lst-kix_list_13-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_13-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_13-1 {
        list-style-type: none;
      }
      .lst-kix_list_6-6 > li {
        counter-increment: lst-ctn-kix_list_6-6;
      }
      ol.lst-kix_list_13-2 {
        list-style-type: none;
      }
      .lst-kix_list_7-3 > li:before {
        content: "" counter(lst-ctn-kix_list_7-3, decimal) ". ";
      }
      ol.lst-kix_list_13-3 {
        list-style-type: none;
      }
      .lst-kix_list_10-0 > li:before {
        content: "(" counter(lst-ctn-kix_list_10-0, decimal) ") ";
      }
      .lst-kix_list_9-7 > li {
        counter-increment: lst-ctn-kix_list_9-7;
      }
      .lst-kix_list_13-6 > li {
        counter-increment: lst-ctn-kix_list_13-6;
      }
      .lst-kix_list_13-8 > li:before {
        content: "" counter(lst-ctn-kix_list_13-8, lower-roman) ". ";
      }
      ol.lst-kix_list_14-6.start {
        counter-reset: lst-ctn-kix_list_14-6 0;
      }
      ol.lst-kix_list_5-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_5-8 {
        list-style-type: none;
      }
      ol.lst-kix_list_5-3 {
        list-style-type: none;
      }
      .lst-kix_list_8-7 > li {
        counter-increment: lst-ctn-kix_list_8-7;
      }
      ol.lst-kix_list_5-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_3-8.start {
        counter-reset: lst-ctn-kix_list_3-8 0;
      }
      ol.lst-kix_list_5-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_5-6 {
        list-style-type: none;
      }
      .lst-kix_list_7-7 > li:before {
        content: "" counter(lst-ctn-kix_list_7-7, lower-latin) ". ";
      }
      ol.lst-kix_list_8-1.start {
        counter-reset: lst-ctn-kix_list_8-1 0;
      }
      .lst-kix_list_9-5 > li {
        counter-increment: lst-ctn-kix_list_9-5;
      }
      .lst-kix_list_5-8 > li {
        counter-increment: lst-ctn-kix_list_5-8;
      }
      .lst-kix_list_10-4 > li:before {
        content: "" counter(lst-ctn-kix_list_10-4, lower-latin) ". ";
      }
      .lst-kix_list_10-8 > li:before {
        content: "" counter(lst-ctn-kix_list_10-8, lower-roman) ". ";
      }
      .lst-kix_list_4-0 > li:before {
        content: "(" counter(lst-ctn-kix_list_4-0, decimal) ") ";
      }
      ol.lst-kix_list_14-3.start {
        counter-reset: lst-ctn-kix_list_14-3 0;
      }
      .lst-kix_list_4-4 > li:before {
        content: "" counter(lst-ctn-kix_list_4-4, lower-latin) ". ";
      }
      ol.lst-kix_list_2-2.start {
        counter-reset: lst-ctn-kix_list_2-2 0;
      }
      .lst-kix_list_9-3 > li:before {
        content: "" counter(lst-ctn-kix_list_9-3, decimal) ". ";
      }
      ol.lst-kix_list_7-0.start {
        counter-reset: lst-ctn-kix_list_7-0 0;
      }
      .lst-kix_list_12-8 > li {
        counter-increment: lst-ctn-kix_list_12-8;
      }
      ol.lst-kix_list_13-2.start {
        counter-reset: lst-ctn-kix_list_13-2 0;
      }
      ol.lst-kix_list_4-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_4-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_4-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_4-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_14-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_14-4.start {
        counter-reset: lst-ctn-kix_list_14-4 0;
      }
      ol.lst-kix_list_14-8 {
        list-style-type: none;
      }
      .lst-kix_list_9-7 > li:before {
        content: "" counter(lst-ctn-kix_list_9-7, lower-latin) ". ";
      }
      .lst-kix_list_2-4 > li {
        counter-increment: lst-ctn-kix_list_2-4;
      }
      ol.lst-kix_list_14-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_3-6.start {
        counter-reset: lst-ctn-kix_list_3-6 0;
      }
      ol.lst-kix_list_14-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_14-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_14-6 {
        list-style-type: none;
      }
      .lst-kix_list_11-4 > li:before {
        content: "" counter(lst-ctn-kix_list_11-4, lower-latin) ". ";
      }
      ol.lst-kix_list_14-0 {
        list-style-type: none;
      }
      .lst-kix_list_12-4 > li:before {
        content: "" counter(lst-ctn-kix_list_12-4, lower-latin) ". ";
      }
      ol.lst-kix_list_14-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_14-2 {
        list-style-type: none;
      }
      .lst-kix_list_5-3 > li {
        counter-increment: lst-ctn-kix_list_5-3;
      }
      ol.lst-kix_list_4-8 {
        list-style-type: none;
      }
      .lst-kix_list_7-4 > li {
        counter-increment: lst-ctn-kix_list_7-4;
      }
      .lst-kix_list_1-0 > li:before {
        content: "(" counter(lst-ctn-kix_list_1-0, decimal) ") ";
      }
      ol.lst-kix_list_4-4 {
        list-style-type: none;
      }
      .lst-kix_list_11-8 > li:before {
        content: "" counter(lst-ctn-kix_list_11-8, lower-roman) ". ";
      }
      .lst-kix_list_12-3 > li {
        counter-increment: lst-ctn-kix_list_12-3;
      }
      ol.lst-kix_list_4-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_2-0.start {
        counter-reset: lst-ctn-kix_list_2-0 0;
      }
      ol.lst-kix_list_4-6 {
        list-style-type: none;
      }
      .lst-kix_list_12-0 > li:before {
        content: "(" counter(lst-ctn-kix_list_12-0, decimal) ") ";
      }
      ol.lst-kix_list_4-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_8-4.start {
        counter-reset: lst-ctn-kix_list_8-4 0;
      }
      .lst-kix_list_1-4 > li:before {
        content: "" counter(lst-ctn-kix_list_1-4, lower-latin) ". ";
      }
      .lst-kix_list_13-0 > li:before {
        content: "(" counter(lst-ctn-kix_list_13-0, decimal) ") ";
      }
      ol.lst-kix_list_3-5.start {
        counter-reset: lst-ctn-kix_list_3-5 0;
      }
      .lst-kix_list_1-6 > li {
        counter-increment: lst-ctn-kix_list_1-6;
      }
      ol.lst-kix_list_13-0.start {
        counter-reset: lst-ctn-kix_list_13-0 0;
      }
      .lst-kix_list_14-4 > li {
        counter-increment: lst-ctn-kix_list_14-4;
      }
      .lst-kix_list_13-4 > li:before {
        content: "" counter(lst-ctn-kix_list_13-4, lower-latin) ". ";
      }
      .lst-kix_list_10-7 > li {
        counter-increment: lst-ctn-kix_list_10-7;
      }
      .lst-kix_list_2-0 > li:before {
        content: "(" counter(lst-ctn-kix_list_2-0, decimal) ") ";
      }
      ol.lst-kix_list_2-1.start {
        counter-reset: lst-ctn-kix_list_2-1 0;
      }
      ol.lst-kix_list_8-3.start {
        counter-reset: lst-ctn-kix_list_8-3 0;
      }
      .lst-kix_list_11-5 > li {
        counter-increment: lst-ctn-kix_list_11-5;
      }
      .lst-kix_list_4-5 > li {
        counter-increment: lst-ctn-kix_list_4-5;
      }
      ol.lst-kix_list_14-5.start {
        counter-reset: lst-ctn-kix_list_14-5 0;
      }
      ol.lst-kix_list_9-8.start {
        counter-reset: lst-ctn-kix_list_9-8 0;
      }
      .lst-kix_list_1-8 > li:before {
        content: "" counter(lst-ctn-kix_list_1-8, lower-roman) ". ";
      }
      .lst-kix_list_12-8 > li:before {
        content: "" counter(lst-ctn-kix_list_12-8, lower-roman) ". ";
      }
      .lst-kix_list_8-2 > li {
        counter-increment: lst-ctn-kix_list_8-2;
      }
      .lst-kix_list_4-1 > li {
        counter-increment: lst-ctn-kix_list_4-1;
      }
      ol.lst-kix_list_12-6.start {
        counter-reset: lst-ctn-kix_list_12-6 0;
      }
      .lst-kix_list_8-1 > li {
        counter-increment: lst-ctn-kix_list_8-1;
      }
      ol.lst-kix_list_8-2.start {
        counter-reset: lst-ctn-kix_list_8-2 0;
      }
      ol.lst-kix_list_3-1.start {
        counter-reset: lst-ctn-kix_list_3-1 0;
      }
      ol.lst-kix_list_6-6.start {
        counter-reset: lst-ctn-kix_list_6-6 0;
      }
      .lst-kix_list_7-0 > li {
        counter-increment: lst-ctn-kix_list_7-0;
      }
      .lst-kix_list_11-0 > li {
        counter-increment: lst-ctn-kix_list_11-0;
      }
      ol.lst-kix_list_1-5.start {
        counter-reset: lst-ctn-kix_list_1-5 0;
      }
      ol.lst-kix_list_9-6.start {
        counter-reset: lst-ctn-kix_list_9-6 0;
      }
      .lst-kix_list_2-3 > li {
        counter-increment: lst-ctn-kix_list_2-3;
      }
      ol.lst-kix_list_4-5.start {
        counter-reset: lst-ctn-kix_list_4-5 0;
      }
      .lst-kix_list_1-2 > li {
        counter-increment: lst-ctn-kix_list_1-2;
      }
      ol.lst-kix_list_14-7.start {
        counter-reset: lst-ctn-kix_list_14-7 0;
      }
      ol.lst-kix_list_11-2.start {
        counter-reset: lst-ctn-kix_list_11-2 0;
      }
      .lst-kix_list_5-2 > li {
        counter-increment: lst-ctn-kix_list_5-2;
      }
      .lst-kix_list_9-2 > li {
        counter-increment: lst-ctn-kix_list_9-2;
      }
      ol.lst-kix_list_8-7.start {
        counter-reset: lst-ctn-kix_list_8-7 0;
      }
      .lst-kix_list_13-2 > li {
        counter-increment: lst-ctn-kix_list_13-2;
      }
      .lst-kix_list_14-3 > li {
        counter-increment: lst-ctn-kix_list_14-3;
      }
      .lst-kix_list_10-3 > li {
        counter-increment: lst-ctn-kix_list_10-3;
      }
      .lst-kix_list_12-1 > li {
        counter-increment: lst-ctn-kix_list_12-1;
      }
      ol.lst-kix_list_1-0.start {
        counter-reset: lst-ctn-kix_list_1-0 0;
      }
      ol.lst-kix_list_13-3.start {
        counter-reset: lst-ctn-kix_list_13-3 0;
      }
      .lst-kix_list_3-0 > li {
        counter-increment: lst-ctn-kix_list_3-0;
      }
      ol.lst-kix_list_4-0.start {
        counter-reset: lst-ctn-kix_list_4-0 0;
      }
      ol.lst-kix_list_11-7.start {
        counter-reset: lst-ctn-kix_list_11-7 0;
      }
      ol.lst-kix_list_14-2.start {
        counter-reset: lst-ctn-kix_list_14-2 0;
      }
      ol.lst-kix_list_2-4.start {
        counter-reset: lst-ctn-kix_list_2-4 0;
      }
      ol.lst-kix_list_1-3 {
        list-style-type: none;
      }
      ol.lst-kix_list_1-4 {
        list-style-type: none;
      }
      .lst-kix_list_2-7 > li:before {
        content: "" counter(lst-ctn-kix_list_2-7, lower-latin) ". ";
      }
      .lst-kix_list_2-7 > li {
        counter-increment: lst-ctn-kix_list_2-7;
      }
      ol.lst-kix_list_1-5 {
        list-style-type: none;
      }
      ol.lst-kix_list_1-6 {
        list-style-type: none;
      }
      ol.lst-kix_list_1-0 {
        list-style-type: none;
      }
      .lst-kix_list_2-5 > li:before {
        content: "" counter(lst-ctn-kix_list_2-5, lower-roman) ". ";
      }
      ol.lst-kix_list_1-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_1-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_10-3.start {
        counter-reset: lst-ctn-kix_list_10-3 0;
      }
      ol.lst-kix_list_9-4.start {
        counter-reset: lst-ctn-kix_list_9-4 0;
      }
      .lst-kix_list_14-6 > li {
        counter-increment: lst-ctn-kix_list_14-6;
      }
      .lst-kix_list_10-1 > li:before {
        content: "" counter(lst-ctn-kix_list_10-1, lower-latin) ". ";
      }
      .lst-kix_list_7-7 > li {
        counter-increment: lst-ctn-kix_list_7-7;
      }
      ol.lst-kix_list_4-3.start {
        counter-reset: lst-ctn-kix_list_4-3 0;
      }
      ol.lst-kix_list_1-7 {
        list-style-type: none;
      }
      ol.lst-kix_list_1-8 {
        list-style-type: none;
      }
      .lst-kix_list_10-7 > li:before {
        content: "" counter(lst-ctn-kix_list_10-7, lower-latin) ". ";
      }
      .lst-kix_list_7-8 > li {
        counter-increment: lst-ctn-kix_list_7-8;
      }
      .lst-kix_list_10-5 > li:before {
        content: "" counter(lst-ctn-kix_list_10-5, lower-roman) ". ";
      }
      ol.lst-kix_list_13-5.start {
        counter-reset: lst-ctn-kix_list_13-5 0;
      }
      .lst-kix_list_10-3 > li:before {
        content: "" counter(lst-ctn-kix_list_10-3, decimal) ". ";
      }
      .lst-kix_list_2-6 > li {
        counter-increment: lst-ctn-kix_list_2-6;
      }
      ol.lst-kix_list_7-3.start {
        counter-reset: lst-ctn-kix_list_7-3 0;
      }
      ol.lst-kix_list_13-8.start {
        counter-reset: lst-ctn-kix_list_13-8 0;
      }
      .lst-kix_list_11-7 > li {
        counter-increment: lst-ctn-kix_list_11-7;
      }
      .lst-kix_list_9-2 > li:before {
        content: "" counter(lst-ctn-kix_list_9-2, lower-roman) ". ";
      }
      ol.lst-kix_list_5-7.start {
        counter-reset: lst-ctn-kix_list_5-7 0;
      }
      ol.lst-kix_list_14-0.start {
        counter-reset: lst-ctn-kix_list_14-0 0;
      }
      .lst-kix_list_12-5 > li {
        counter-increment: lst-ctn-kix_list_12-5;
      }
      .lst-kix_list_5-5 > li {
        counter-increment: lst-ctn-kix_list_5-5;
      }
      .lst-kix_list_9-0 > li:before {
        content: "(" counter(lst-ctn-kix_list_9-0, decimal) ") ";
      }
      .lst-kix_list_3-4 > li {
        counter-increment: lst-ctn-kix_list_3-4;
      }
      ol.lst-kix_list_10-7 {
        list-style-type: none;
      }
      .lst-kix_list_9-6 > li:before {
        content: "" counter(lst-ctn-kix_list_9-6, decimal) ". ";
      }
      ol.lst-kix_list_10-8 {
        list-style-type: none;
      }
      ol.lst-kix_list_10-3 {
        list-style-type: none;
      }
      .lst-kix_list_9-4 > li:before {
        content: "" counter(lst-ctn-kix_list_9-4, lower-latin) ". ";
      }
      ol.lst-kix_list_10-4 {
        list-style-type: none;
      }
      ol.lst-kix_list_10-5 {
        list-style-type: none;
      }
      .lst-kix_list_11-3 > li:before {
        content: "" counter(lst-ctn-kix_list_11-3, decimal) ". ";
      }
      ol.lst-kix_list_10-6 {
        list-style-type: none;
      }
      .lst-kix_list_6-3 > li {
        counter-increment: lst-ctn-kix_list_6-3;
      }
      ol.lst-kix_list_10-0 {
        list-style-type: none;
      }
      ol.lst-kix_list_10-1 {
        list-style-type: none;
      }
      ol.lst-kix_list_1-3.start {
        counter-reset: lst-ctn-kix_list_1-3 0;
      }
      ol.lst-kix_list_10-2 {
        list-style-type: none;
      }
      ol.lst-kix_list_12-1.start {
        counter-reset: lst-ctn-kix_list_12-1 0;
      }
      ol.lst-kix_list_1-2.start {
        counter-reset: lst-ctn-kix_list_1-2 0;
      }
      .lst-kix_list_11-5 > li:before {
        content: "" counter(lst-ctn-kix_list_11-5, lower-roman) ". ";
      }
      ol.lst-kix_list_6-1.start {
        counter-reset: lst-ctn-kix_list_6-1 0;
      }
      .lst-kix_list_9-8 > li:before {
        content: "" counter(lst-ctn-kix_list_9-8, lower-roman) ". ";
      }
      .lst-kix_list_1-1 > li:before {
        content: "" counter(lst-ctn-kix_list_1-1, lower-latin) ". ";
      }
      .lst-kix_list_11-7 > li:before {
        content: "" counter(lst-ctn-kix_list_11-7, lower-latin) ". ";
      }
      .lst-kix_list_8-5 > li {
        counter-increment: lst-ctn-kix_list_8-5;
      }
      .lst-kix_list_1-3 > li:before {
        content: "" counter(lst-ctn-kix_list_1-3, decimal) ". ";
      }
      ol.lst-kix_list_10-5.start {
        counter-reset: lst-ctn-kix_list_10-5 0;
      }
      .lst-kix_list_4-8 > li {
        counter-increment: lst-ctn-kix_list_4-8;
      }
      .lst-kix_list_1-7 > li:before {
        content: "" counter(lst-ctn-kix_list_1-7, lower-latin) ". ";
      }
      ol.lst-kix_list_2-7.start {
        counter-reset: lst-ctn-kix_list_2-7 0;
      }
      .lst-kix_list_1-5 > li:before {
        content: "" counter(lst-ctn-kix_list_1-5, lower-roman) ". ";
      }
      ol.lst-kix_list_9-1.start {
        counter-reset: lst-ctn-kix_list_9-1 0;
      }
      .lst-kix_list_14-7 > li {
        counter-increment: lst-ctn-kix_list_14-7;
      }
      .lst-kix_list_5-6 > li {
        counter-increment: lst-ctn-kix_list_5-6;
      }
      ol.lst-kix_list_7-5.start {
        counter-reset: lst-ctn-kix_list_7-5 0;
      }
      .lst-kix_list_2-1 > li:before {
        content: "" counter(lst-ctn-kix_list_2-1, lower-latin) ". ";
      }
      .lst-kix_list_2-3 > li:before {
        content: "" counter(lst-ctn-kix_list_2-3, decimal) ". ";
      }
      .lst-kix_list_11-8 > li {
        counter-increment: lst-ctn-kix_list_11-8;
      }
      .lst-kix_list_3-1 > li {
        counter-increment: lst-ctn-kix_list_3-1;
      }
      .lst-kix_list_9-1 > li {
        counter-increment: lst-ctn-kix_list_9-1;
      }
      ol.lst-kix_list_7-7.start {
        counter-reset: lst-ctn-kix_list_7-7 0;
      }
      .lst-kix_list_3-1 > li:before {
        content: "" counter(lst-ctn-kix_list_3-1, lower-latin) ". ";
      }
      .lst-kix_list_3-2 > li:before {
        content: "" counter(lst-ctn-kix_list_3-2, lower-roman) ". ";
      }
      .lst-kix_list_14-0 > li {
        counter-increment: lst-ctn-kix_list_14-0;
      }
      .lst-kix_list_8-1 > li:before {
        content: "" counter(lst-ctn-kix_list_8-1, lower-latin) ". ";
      }
      ol.lst-kix_list_1-8.start {
        counter-reset: lst-ctn-kix_list_1-8 0;
      }
      .lst-kix_list_8-2 > li:before {
        content: "" counter(lst-ctn-kix_list_8-2, lower-roman) ". ";
      }
      .lst-kix_list_6-0 > li {
        counter-increment: lst-ctn-kix_list_6-0;
      }
      .lst-kix_list_3-5 > li:before {
        content: "" counter(lst-ctn-kix_list_3-5, lower-roman) ". ";
      }
      .lst-kix_list_12-0 > li {
        counter-increment: lst-ctn-kix_list_12-0;
      }
      ol.lst-kix_list_12-3.start {
        counter-reset: lst-ctn-kix_list_12-3 0;
      }
      ol.lst-kix_list_11-5.start {
        counter-reset: lst-ctn-kix_list_11-5 0;
      }
      .lst-kix_list_8-5 > li:before {
        content: "" counter(lst-ctn-kix_list_8-5, lower-roman) ". ";
      }
      .lst-kix_list_11-1 > li {
        counter-increment: lst-ctn-kix_list_11-1;
      }
      .lst-kix_list_8-6 > li:before {
        content: "" counter(lst-ctn-kix_list_8-6, decimal) ". ";
      }
      .lst-kix_list_2-0 > li {
        counter-increment: lst-ctn-kix_list_2-0;
      }
      .lst-kix_list_3-6 > li:before {
        content: "" counter(lst-ctn-kix_list_3-6, decimal) ". ";
      }
      ol.lst-kix_list_5-0.start {
        counter-reset: lst-ctn-kix_list_5-0 0;
      }
      .lst-kix_list_11-2 > li:before {
        content: "" counter(lst-ctn-kix_list_11-2, lower-roman) ". ";
      }
      ol.lst-kix_list_4-2.start {
        counter-reset: lst-ctn-kix_list_4-2 0;
      }
      ol.lst-kix_list_11-6.start {
        counter-reset: lst-ctn-kix_list_11-6 0;
      }
      ol.lst-kix_list_12-4.start {
        counter-reset: lst-ctn-kix_list_12-4 0;
      }
      ol.lst-kix_list_10-1.start {
        counter-reset: lst-ctn-kix_list_10-1 0;
      }
      .lst-kix_list_4-4 > li {
        counter-increment: lst-ctn-kix_list_4-4;
      }
      ol.lst-kix_list_6-4.start {
        counter-reset: lst-ctn-kix_list_6-4 0;
      }
      ol.lst-kix_list_5-6.start {
        counter-reset: lst-ctn-kix_list_5-6 0;
      }
      ol.lst-kix_list_4-1.start {
        counter-reset: lst-ctn-kix_list_4-1 0;
      }
      .lst-kix_list_7-3 > li {
        counter-increment: lst-ctn-kix_list_7-3;
      }
      ol.lst-kix_list_7-8.start {
        counter-reset: lst-ctn-kix_list_7-8 0;
      }
      .lst-kix_list_12-4 > li {
        counter-increment: lst-ctn-kix_list_12-4;
      }
      ol.lst-kix_list_11-0.start {
        counter-reset: lst-ctn-kix_list_11-0 0;
      }
      .lst-kix_list_3-3 > li {
        counter-increment: lst-ctn-kix_list_3-3;
      }
      ol.lst-kix_list_6-3.start {
        counter-reset: lst-ctn-kix_list_6-3 0;
      }
      ol.lst-kix_list_10-2.start {
        counter-reset: lst-ctn-kix_list_10-2 0;
      }
      .lst-kix_list_12-7 > li {
        counter-increment: lst-ctn-kix_list_12-7;
      }
      ol.lst-kix_list_5-5.start {
        counter-reset: lst-ctn-kix_list_5-5 0;
      }
      .lst-kix_list_6-2 > li {
        counter-increment: lst-ctn-kix_list_6-2;
      }
      .lst-kix_list_2-6 > li:before {
        content: "" counter(lst-ctn-kix_list_2-6, decimal) ". ";
      }
      .lst-kix_list_14-5 > li {
        counter-increment: lst-ctn-kix_list_14-5;
      }
      .lst-kix_list_7-1 > li:before {
        content: "" counter(lst-ctn-kix_list_7-1, lower-latin) ". ";
      }
      .lst-kix_list_7-5 > li:before {
        content: "" counter(lst-ctn-kix_list_7-5, lower-roman) ". ";
      }
      .lst-kix_list_13-5 > li {
        counter-increment: lst-ctn-kix_list_13-5;
      }
      .lst-kix_list_9-6 > li {
        counter-increment: lst-ctn-kix_list_9-6;
      }
      ol.lst-kix_list_5-4.start {
        counter-reset: lst-ctn-kix_list_5-4 0;
      }
      ol.lst-kix_list_11-1.start {
        counter-reset: lst-ctn-kix_list_11-1 0;
      }
      .lst-kix_list_13-6 > li:before {
        content: "" counter(lst-ctn-kix_list_13-6, decimal) ". ";
      }
      ol.lst-kix_list_5-1.start {
        counter-reset: lst-ctn-kix_list_5-1 0;
      }
      .lst-kix_list_6-7 > li {
        counter-increment: lst-ctn-kix_list_6-7;
      }
      .lst-kix_list_10-6 > li {
        counter-increment: lst-ctn-kix_list_10-6;
      }
      .lst-kix_list_11-6 > li {
        counter-increment: lst-ctn-kix_list_11-6;
      }
      .lst-kix_list_1-7 > li {
        counter-increment: lst-ctn-kix_list_1-7;
      }
      ol.lst-kix_list_10-0.start {
        counter-reset: lst-ctn-kix_list_10-0 0;
      }
      .lst-kix_list_7-5 > li {
        counter-increment: lst-ctn-kix_list_7-5;
      }
      .lst-kix_list_11-4 > li {
        counter-increment: lst-ctn-kix_list_11-4;
      }
      .lst-kix_list_3-8 > li {
        counter-increment: lst-ctn-kix_list_3-8;
      }
      ol.lst-kix_list_6-8.start {
        counter-reset: lst-ctn-kix_list_6-8 0;
      }
      .lst-kix_list_10-2 > li:before {
        content: "" counter(lst-ctn-kix_list_10-2, lower-roman) ". ";
      }
      .lst-kix_list_4-6 > li {
        counter-increment: lst-ctn-kix_list_4-6;
      }
      .lst-kix_list_13-7 > li {
        counter-increment: lst-ctn-kix_list_13-7;
      }
      ol.lst-kix_list_1-7.start {
        counter-reset: lst-ctn-kix_list_1-7 0;
      }
      .lst-kix_list_1-5 > li {
        counter-increment: lst-ctn-kix_list_1-5;
      }
      ol.lst-kix_list_6-5.start {
        counter-reset: lst-ctn-kix_list_6-5 0;
      }
      .lst-kix_list_4-2 > li:before {
        content: "" counter(lst-ctn-kix_list_4-2, lower-roman) ". ";
      }
      .lst-kix_list_4-6 > li:before {
        content: "" counter(lst-ctn-kix_list_4-6, decimal) ". ";
      }
      .lst-kix_list_10-8 > li {
        counter-increment: lst-ctn-kix_list_10-8;
      }
      .lst-kix_list_10-6 > li:before {
        content: "" counter(lst-ctn-kix_list_10-6, decimal) ". ";
      }
      .lst-kix_list_9-1 > li:before {
        content: "" counter(lst-ctn-kix_list_9-1, lower-latin) ". ";
      }
      ol.lst-kix_list_12-7.start {
        counter-reset: lst-ctn-kix_list_12-7 0;
      }
      ol.lst-kix_list_6-7.start {
        counter-reset: lst-ctn-kix_list_6-7 0;
      }
      .lst-kix_list_12-2 > li {
        counter-increment: lst-ctn-kix_list_12-2;
      }
      .lst-kix_list_9-5 > li:before {
        content: "" counter(lst-ctn-kix_list_9-5, lower-roman) ". ";
      }
      .lst-kix_list_12-2 > li:before {
        content: "" counter(lst-ctn-kix_list_12-2, lower-roman) ". ";
      }
      ol.lst-kix_list_12-8.start {
        counter-reset: lst-ctn-kix_list_12-8 0;
      }
      .lst-kix_list_11-6 > li:before {
        content: "" counter(lst-ctn-kix_list_11-6, decimal) ". ";
      }
      ol.lst-kix_list_11-3.start {
        counter-reset: lst-ctn-kix_list_11-3 0;
      }
      .lst-kix_list_1-2 > li:before {
        content: "" counter(lst-ctn-kix_list_1-2, lower-roman) ". ";
      }
      .lst-kix_list_10-1 > li {
        counter-increment: lst-ctn-kix_list_10-1;
      }
      .lst-kix_list_1-0 > li {
        counter-increment: lst-ctn-kix_list_1-0;
      }
      .lst-kix_list_8-8 > li {
        counter-increment: lst-ctn-kix_list_8-8;
      }
      .lst-kix_list_1-6 > li:before {
        content: "" counter(lst-ctn-kix_list_1-6, decimal) ". ";
      }
      .lst-kix_list_12-6 > li:before {
        content: "" counter(lst-ctn-kix_list_12-6, decimal) ". ";
      }
      ol.lst-kix_list_11-4.start {
        counter-reset: lst-ctn-kix_list_11-4 0;
      }
      .lst-kix_list_2-2 > li:before {
        content: "" counter(lst-ctn-kix_list_2-2, lower-roman) ". ";
      }
      .lst-kix_list_13-2 > li:before {
        content: "" counter(lst-ctn-kix_list_13-2, lower-roman) ". ";
      }
      ol.lst-kix_list_5-2.start {
        counter-reset: lst-ctn-kix_list_5-2 0;
      }
      ol {
        margin: 0;
        padding: 0;
      }
      table td,
      table th {
        padding: 0;
      }
      .c0 {
        border-right-style: solid;
        padding-top: 0pt;
        border-top-width: 0pt;
        border-bottom-color: #000000;
        border-right-width: 0pt;
        padding-left: 0pt;
        border-left-color: #000000;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        border-top-color: #000000;
        border-bottom-style: solid;
        orphans: 2;
        widows: 2;
        text-align: justify;
        padding-right: 0pt;
      }
      .c14 {
        border-right-style: solid;
        padding-top: 0pt;
        border-top-width: 0pt;
        border-bottom-color: #000000;
        border-right-width: 0pt;
        padding-left: 0pt;
        border-left-color: #000000;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        border-top-color: #000000;
        border-bottom-style: solid;
        orphans: 2;
        widows: 2;
        text-align: center;
        padding-right: 0pt;
      }
      .c16 {
        border-right-style: solid;
        padding-top: 0pt;
        border-top-width: 0pt;
        border-bottom-color: #000000;
        border-right-width: 0pt;
        padding-left: 0pt;
        border-left-color: #000000;
        padding-bottom: 0pt;
        line-height: 1.0791666666666666;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        border-top-color: #000000;
        border-bottom-style: solid;
        orphans: 2;
        widows: 2;
        text-align: left;
        padding-right: 0pt;
      }
      .c15 {
        border-right-style: solid;
        padding: 0pt 3.5pt 0pt 3.5pt;
        border-bottom-color: #000000;
        border-top-width: 0pt;
        border-right-width: 0pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 0pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 0pt;
        border-top-color: #000000;
        border-bottom-style: solid;
      }
      .c1 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10pt;
        font-family: "Arial";
        font-style: normal;
      }
      .c2 {
        color: #000000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10pt;
        font-family: "Arial";
        font-style: normal;
      }
      .c13 {
        color: #000000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Arial";
        font-style: normal;
      }
      .c9 {
        padding-top: 6pt;
        padding-bottom: 6pt;
        line-height: 1.1500000000000001;
        orphans: 2;
        widows: 2;
        text-align: justify;
      }
      .c7 {
        padding-top: 6pt;
        padding-bottom: 6pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: justify;
      }
      .c4 {
        padding-top: 6pt;
        padding-bottom: 6pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: center;
      }
      .c5 {
        padding-top: 6pt;
        padding-bottom: 6pt;
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      .c17 {
        border-spacing: 0;
        border-collapse: collapse;
        margin-right: auto;
      }
      .c10 {
        background-color: #ffffff;
        padding: 72pt 72pt 72pt 72pt;
      }
      .c11 {
        height: 0pt;
      }
      .c8 {
        margin-left: 72pt;
      }
      .c6 {
        margin-left: 31.7pt;
      }
      .c12 {
        page-break-after: avoid;
      }
      .c3 {
        height: 10pt;
      }
      .title {
        padding-top: 24pt;
        color: #000000;
        font-weight: 700;
        font-size: 36pt;
        padding-bottom: 6pt;
        font-family: "Arial";
        line-height: 1;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      .subtitle {
        padding-top: 18pt;
        color: #666666;
        font-size: 24pt;
        padding-bottom: 4pt;
        font-family: "Georgia";
        line-height: 1;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      li {
        color: #000000;
        font-size: 10pt;
        font-family: "Arial";
      }
      p {
        margin: 0;
        color: #000000;
        font-size: 10pt;
        font-family: "Arial";
      }
      h1 {
        padding-top: 11.9pt;
        color: #000000;
        font-weight: 700;
        font-size: 16pt;
        padding-bottom: 3.1pt;
        font-family: "Helvetica Neue";
        line-height: 1;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h2 {
        padding-top: 11.9pt;
        color: #000000;
        font-weight: 700;
        font-size: 14pt;
        padding-bottom: 3.1pt;
        font-family: "Helvetica Neue";
        line-height: 1;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h3 {
        padding-top: 11.9pt;
        color: #000000;
        font-weight: 700;
        font-size: 13pt;
        padding-bottom: 3.1pt;
        font-family: "Helvetica Neue";
        line-height: 1;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h4 {
        padding-top: 11.9pt;
        color: #000000;
        font-weight: 700;
        font-size: 14pt;
        padding-bottom: 3.1pt;
        font-family: "Times";
        line-height: 1;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h5 {
        padding-top: 11.9pt;
        color: #000000;
        font-weight: 700;
        font-size: 13pt;
        padding-bottom: 3.1pt;
        font-family: "Times";
        line-height: 1;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h6 {
        padding-top: 11.9pt;
        color: #000000;
        font-weight: 700;
        font-size: 11pt;
        padding-bottom: 3.1pt;
        font-family: "Times";
        line-height: 1;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
    }
    .privacy-policy {
      .lst-kix_3rfj0uhcs3q3-5 > li:before {
        content: "\0025a0";
      }
      .lst-kix_3rfj0uhcs3q3-7 > li:before {
        content: "\0025cb";
      }
      .lst-kix_3rfj0uhcs3q3-2 > li:before {
        content: "\0025a0";
      }
      .lst-kix_3rfj0uhcs3q3-6 > li:before {
        content: "\0025cf";
      }
      .lst-kix_3rfj0uhcs3q3-3 > li:before {
        content: "\0025cf";
      }
      li.li-bullet-0:before {
        margin-left: -18pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 18pt;
      }
      .lst-kix_3rfj0uhcs3q3-4 > li:before {
        content: "\\0025cb  ";
      }
      ul.lst-kix_3rfj0uhcs3q3-1 {
        list-style-type: none;
      }
      ul.lst-kix_3rfj0uhcs3q3-0 {
        list-style-type: none;
      }
      ul.lst-kix_3rfj0uhcs3q3-3 {
        list-style-type: none;
      }
      ul.lst-kix_3rfj0uhcs3q3-2 {
        list-style-type: none;
      }
      ul.lst-kix_3rfj0uhcs3q3-5 {
        list-style-type: none;
      }
      ul.lst-kix_3rfj0uhcs3q3-4 {
        list-style-type: none;
      }
      ul.lst-kix_3rfj0uhcs3q3-7 {
        list-style-type: none;
      }
      .lst-kix_3rfj0uhcs3q3-1 > li:before {
        content: "\\0025cb  ";
      }
      ul.lst-kix_3rfj0uhcs3q3-6 {
        list-style-type: none;
      }
      ul.lst-kix_3rfj0uhcs3q3-8 {
        list-style-type: none;
      }
      .lst-kix_3rfj0uhcs3q3-8 > li:before {
        content: "\0025a0";
      }
      .lst-kix_3rfj0uhcs3q3-0 > li:before {
        content: "\0025cf";
      }
      ol {
        margin: 0;
        padding: 0;
      }
      table td,
      table th {
        padding: 0;
      }
      .c5 {
        background-color: #ffffff;
        color: #20252b;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10pt;
        font-family: "Arial";
        font-style: normal;
      }
      .c2 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10pt;
        font-family: "Arial";
        font-style: normal;
      }
      .c0 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: left;
        height: 11pt;
      }
      .c3 {
        color: #000000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 10pt;
        font-family: "Arial";
        font-style: normal;
      }
      .c9 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: center;
      }
      .c10 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-family: "Arial";
        font-style: italic;
      }
      .c1 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      .c12 {
        background-color: #ffffff;
        // padding: 72pt 72pt 72pt 72pt;
      }
      .c11 {
        padding: 0;
        margin: 0;
      }
      .c4 {
        margin-left: 36pt;
        padding-left: 0pt;
      }
      .c7 {
        background-color: #ffffff;
        color: #20252b;
      }
      .c6 {
        font-size: 10pt;
      }
      .c8 {
        margin-left: 36pt;
      }
      .title {
        padding-top: 0pt;
        color: #000000;
        font-size: 26pt;
        padding-bottom: 3pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      .subtitle {
        padding-top: 0pt;
        color: #666666;
        font-size: 15pt;
        padding-bottom: 16pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      li {
        color: #000000;
        font-size: 11pt;
        font-family: "Arial";
      }
      p {
        margin: 0;
        color: #000000;
        font-size: 11pt;
        font-family: "Arial";
      }
      h1 {
        padding-top: 20pt;
        color: #000000;
        font-size: 20pt;
        padding-bottom: 6pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h2 {
        padding-top: 18pt;
        color: #000000;
        font-size: 16pt;
        padding-bottom: 6pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h3 {
        padding-top: 16pt;
        color: #434343;
        font-size: 14pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h4 {
        padding-top: 14pt;
        color: #666666;
        font-size: 12pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h5 {
        padding-top: 12pt;
        color: #666666;
        font-size: 11pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h6 {
        padding-top: 12pt;
        color: #666666;
        font-size: 11pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
    }
  }
}
