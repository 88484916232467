.cloading {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff88;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    z-index: -1;
    transition: all 0.2s ease-in-out;
    &.logined {
        padding-left: 18.6rem;
    }
    &.show {
        opacity: 1;
        z-index: 9999;
    }

    svg {
        circle {
            fill: var(--bs-primary);
        }
    }
}