:global {
    .mini-report-wrapper {
        .btn-reload {
            padding: 0;
            height: 32px;
        }

        .col-data {
            display: flex;
            align-items: center;
            min-height: 47px;
        }

        .text-line-throught {
            text-decoration: line-through;
            color: rgba(0, 0, 0, 0.45);
        }
    }

    .mini-group {
        border-radius: 0.5rem;
        padding: 1rem;
        margin-bottom: 1rem;
        box-shadow: 0px 0px 2.8rem rgba(167, 160, 137, 0.4);
    }
}