:global {
  .v3dmodel {
    position: relative;
    .ant-modal-content {
      padding: 0;
      overflow: hidden;
    }
    .ant-modal-body {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 30vw;
    }
  }

  .icon-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .part-svg {
    // height: ;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: contain;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 0;
      z-index: -1;
      transition: 0.3s ease;
    }
    &:hover::before {
      opacity: 1;
      z-index: 1;
    }

    svg {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .part-svg-preview {
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: auto;
      height: auto;
      max-width: 90%;
      max-height: 90%;
    }
  }

  .canvasContainer {
    position: relative;
    width: 100%;
    height: 70vh;
    min-width: 100px;
    min-height: 100px;
  }

  .layers {
    background-color: rgba(var(--bs-primary-rgb), 0.1);
  }
}
