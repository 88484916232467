:global {
  .input-inline-api {
    position: relative;
    min-height: 2.12rem;
    .text-input-value {
      cursor: text;
      width: 100%;
      padding: 0.34rem 0.6rem;
      // margin-left: -0.6rem;
      // margin-right: -0.6rem;
      border-radius: 5px;
      // border: 1px solid #00000044;
      // background-color: #ffffff;
      transition: all 0.2s ease;

      white-space: pre;
      &:hover {
        background-color: #e2e2e2;
      }
    }
    .b-actions {
      position: absolute;
      bottom: -0.2rem;
      right: 0;
      transform: translateY(100%);
      z-index: 9999;
    }
    .ant-btn {
      box-shadow: 0px 5px 1rem rgba(167, 160, 137, 0.8);
      &:hover {
        color: #000000;
      }
      &.ant-btn-default {
        background-color: #f0f0f0;
        border-color: #e2e2e2;

        &:hover {
          background-color: #e2e2e2;
          border-color: #e2e2e2;
        }
      }
    }
    .inline-has-error {
      .btn-action-inline {
        bottom: 23px;
      }
    }
  }
}
