:global {
    .full-width-input {
        width: 100%;
    }

    .card-gasket {
        cursor: pointer;
    }

    .grid-area {
        height: 100%;
        width: 100%;
        outline: 1px #000 solid;
        display: flex;
        align-items: center;
        position: relative;

        .grid-horizontal-ruler {
            top: 0;
        }

        .grid-vertical-ruler {
            left: 0;
        }
    }

    .custom-dxf {
        .selected {
            border-color: #facb0f;
            background-color: #fef2c347;
            cursor: not-allowed;
        }

        .custom-form-dxf {
            .ant-input {
                height: 48px;
            }
        }
    }
}