:global {
  .project-status {
    font-weight: 700;
    border: none;
    padding: 0.2rem 1rem;
    border-radius: 0.3rem;
    &.ant-tag-processing {
      color: #21a4d4;
      background: #dcf2f9;
    }

    &.ant-tag-success {
        color: #01904F;
        background: #D8F8E9;
    }

    &.ant-tag-warning {
        color: #D4740C;
        background: #FCE1C5;
    }

    &.ant-tag-volcano {
        color: #D8382C;
        background: #FDE1DF;
    }
  }
}
