:global {
  .box-img {
    text-align: center;
    text-transform: uppercase;
  }
  .process-img {
    position: relative;
    width: 100%;
    background-color: #f1f1f1;
    aspect-ratio: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .view-item {
    .table-part {
      white-space: nowrap;
      font-size: 0.6vw;

      .part-item {
        cursor: pointer;
        transition: all 0.3s;
        &.active {
          background-color: #ffcb3d;
        }
      }
    }
    .v-title {
      padding: 0.2vw 0;
      text-align: center;
      background-color: rgba(246, 246, 246, 1);
      font-size: 26px;
      line-height: 1.8em;
    }

    .box-diagram {
      svg {
        max-height: 20vw;

        .text-key {
          cursor: pointer;
          fill: #000;

          &.active {
            fill: #ffcb3d;
          }
        }
      }

      .text-key {
        cursor: pointer;
      }
    }

    .table-part {
      overflow-x: auto;
    }
  }
}
