:global {
  .paid-stamp {
    background-image: url(../../../assets/images/paid-stamp-icon.svg);
    height: 120px;
    width: 150px;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    bottom: -15px;
    right: -15px;
    opacity: 0.4;
  }

  .btn-pay-full-width {
    button {
      width: 100%;
    }
  }
}
